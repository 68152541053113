import React from 'react'
import { AriaMenuOptions } from '@react-aria/menu'

interface SubmenuProps<T> extends AriaMenuOptions<T> {
  /** The level of the submenu. */
  submenuLevel: number
}
const SubmenuContext = React.createContext<SubmenuProps<unknown> | null>(null)
export const useSubmenuContext = () => React.useContext(SubmenuContext)
export const SubmenuProvider = SubmenuContext.Provider
