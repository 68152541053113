/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgCheckCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    color='currentColor'
    stroke='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M6.55025 10.4497L8.67157 12.5711L13.6213 7.62132'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      fill='none'
      strokeLinejoin='round'
    />
    <circle cx={10} cy={10} r={9} fill='none' strokeWidth={2} />
  </svg>
)
export default SvgCheckCircle
