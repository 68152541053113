import React from 'react'
import { createContextWithHook } from '@chilipiper/utils'

const { ActionBarUsageCheckBaseProvider, useActionBarUsageCheckContext } =
  createContextWithHook<null>()('ActionBarUsageCheck')

/** Throws if used outside ActionBarUsageCheck */
export const useActionBarUsageCheck = useActionBarUsageCheckContext

export const ActionBarUsageCheck = ({ children }: { children: React.ReactNode }) => {
  return <ActionBarUsageCheckBaseProvider value={null}>{children}</ActionBarUsageCheckBaseProvider>
}
