import React, { ReactNode, useCallback, useState } from 'react'
import { Portal } from 'react-portal'
import { nanoid } from 'nanoid'
import { createContextWithHook } from '@chilipiper/utils'
import { Snackbar } from './Snackbar'
import { SnackbarCustomizations, SnackbarShow, SnackbarWithKey } from './types'
import { getSnackbarChildren } from './helpers'

type State = {
  addSnackbar: (snackbar: SnackbarCustomizations) => void
  showSnackbar: (snackbar: SnackbarShow) => void
}

export const { useSnackbarContext, SnackbarBaseProvider, ...other } =
  createContextWithHook<State>()('Snackbar')

/** @deprecated Use `useShowToast` from `@chilipiper/design-system/src/new` instead */
export const withSnackbarContext = other.withSnackbarContext

type Props = {
  children: ReactNode
}

/** @deprecated Use `ToastProvider` from `@chilipiper/design-system/src/new` instead */
export const SnackbarProvider = ({ children }: Props) => {
  const [snackbars, setSnackbars] = useState<Array<SnackbarWithKey>>([])
  const displayedSnackbar = snackbars.length ? snackbars[0] : undefined

  const addSnackbar = useCallback(
    (snackbar: SnackbarCustomizations) =>
      setSnackbars(snackbars => [...snackbars, { ...snackbar, key: nanoid() }]),
    []
  )

  const showSnackbar = useCallback(
    (props: SnackbarShow) => {
      addSnackbar({
        children: getSnackbarChildren(props),
        ...props,
      })
    },
    [addSnackbar]
  )

  const value = React.useMemo(() => ({ addSnackbar, showSnackbar }), [addSnackbar, showSnackbar])

  return (
    <SnackbarBaseProvider value={value}>
      {children}
      {displayedSnackbar &&
        (process.env.NODE_ENV === 'test' ? (
          <Snackbar
            onClose={() => setSnackbars(snackbars => snackbars.slice(1))}
            {...displayedSnackbar}
          />
        ) : (
          <Portal>
            <Snackbar
              onClose={() => setSnackbars(snackbars => snackbars.slice(1))}
              {...displayedSnackbar}
            />
          </Portal>
        ))}
    </SnackbarBaseProvider>
  )
}
