import { RefObject, useEffect } from 'react'

export const useElementScrolledOut = (elementRef: RefObject<HTMLElement>, onClose: () => void) => {
  useEffect(() => {
    if (!elementRef.current) {
      return
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          onClose()
        }
      })
    })
    observer.observe(elementRef.current)
    return () => observer.disconnect()
  }, [onClose, elementRef])
}
