import { palette } from './palette'

export const contextualTextPalette = {
  'text/action-caution': palette['red-dark-100'],
  'text/action-disabled': palette['neutral-dark-48'],
  'text/action-inform': palette['blue-dark-100'],
  'text/action-item': palette['neutral-dark-100'],
  'text/action-major': palette['purple-dark-100'],
  'text/action-oncolor': palette['white-100'],
  'text/action-major-oncolor': palette['white-100'],
  'text/action-minor': palette['neutral-dark-64'],
  'text/action-minor-oncolor': palette['white-100'],
  'text/action-moderate': palette['neutral-dark-100'],
  'text/deprecated-action-moderate': palette['teal-dark-100'],
  'text/action-warning': palette['yellow-dark-100'],
  'text/action-brand': palette['orange-dark-100'],
  'text/body-major-oncolor': palette['white-100'],
  'text/body-major': palette['neutral-dark-100'],
  'text/body-moderate-oncolor': palette['white-64'],
  'text/body-moderate': palette['neutral-dark-64'],
  'text/body-warning': palette['yellow-dark-100'],
  'text/calendar-item-blue': palette['blue-dark-100'],
  'text/calendar-item-green': palette['green-dark-100'],
  'text/calendar-item-yellow': palette['yellow-dark-100'],
  'text/calendar-item-purple': palette['purple-dark-100'],
  'text/cell': palette['neutral-dark-100'],
  'text/counter': palette['teal-dark-100'],
  'text/date-picker-item-today': palette['red-dark-100'],
  'text/detail-major': palette['neutral-dark-100'],
  'text/detail-moderate': palette['neutral-dark-64'],
  'text/disabled': palette['neutral-dark-48'],
  'text/empty-value': palette['neutral-dark-32'],
  'text/error': palette['red-dark-100'],
  'text/form-label-major': palette['neutral-dark-100'],
  'text/form-label-moderate': palette['neutral-dark-64'],
  'text/form-placeholder': palette['neutral-dark-64'],
  'text/form-value': palette['neutral-dark-100'],
  'text/form-value-disabled': palette['neutral-dark-48'],
  'text/form-error': palette['red-dark-100'],
  'text/form-success': palette['green-dark-100'],
  'text/body-success': palette['green-dark-100'],
  'text/heading-major': palette['neutral-dark-100'],
  'text/heading-major-oncolor': palette['white-100'],
  'text/heading-minor': palette['neutral-dark-72'],
  'text/heading-moderate': palette['neutral-dark-64'],
  'text/list-item': palette['neutral-dark-100'],
  'text/list-item-selected': palette['neutral-dark-100'],
  'text/list-item-unselected': palette['neutral-dark-100'],
  'text/on-color': palette['white-100'],
  'text/tab-item-selected-oncolor': palette['white-100'],
  'text/tab-item-selected': palette['orange-dark-100'],
  'text/tab-item-minor-selected': palette['neutral-dark-100'],
  'text/tab-item-unselected': palette['neutral-dark-64'],
  'text/date-picker-item-today: ': palette['red-dark-100'],
}
