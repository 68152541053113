/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgRemove = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='M6.586 8 2.793 4.207l1.414-1.414L8 6.586l3.793-3.793 1.414 1.414L9.414 8l3.793 3.793-1.414 1.414L8 9.414l-3.793 3.793-1.414-1.414L6.586 8Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgRemove
