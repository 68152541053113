import React, { useRef } from 'react'
import { AriaPositionProps, mergeProps, useHover } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'
import { Popover } from '../../../core-components/popover/Popover'
import {
  Tip,
  TipActionBar,
  TipBody,
  TipCustomContent,
  TipList,
  TipMedia,
  TipText,
  TipTitle,
  validateTipChildren,
} from '../tip/Tip'

type Props = {
  children: React.ComponentProps<typeof Popover>['trigger']
  content: React.ReactNode
  crossOffset?: number
  'data-test-id'?: string
  delay?: number
  isDisabled?: boolean
  offset?: number
  placement?: AriaPositionProps['placement']
}
export const Toggletip = ({
  children,
  placement = 'top',
  isDisabled,
  'data-test-id': dataTestId,
  content,
  offset = 8,
  crossOffset,
  delay = 500,
}: Props) => {
  validateTipChildren(content, true, 'Toggletip')

  const state = useTooltipTriggerState({ isDisabled, delay, closeDelay: 250 })

  const tipRef = useRef<HTMLDivElement>(null)
  const { hoverProps } = useHover({
    onHoverStart: () => state.open(),
    onHoverEnd: () => state.close(),
  })
  return (
    <Popover
      trigger={props => children(mergeProps(props, hoverProps))}
      placement={placement}
      isOpen={!isDisabled && state.isOpen}
      onOpenChange={isOpen => (isOpen ? state.open(true) : state.close(true))}
      crossOffset={crossOffset}
      offset={offset}
    >
      <Tip tipRef={tipRef} data-test-id={dataTestId} containerAttributes={hoverProps}>
        {content}
      </Tip>
    </Popover>
  )
}

Toggletip.List = TipList
Toggletip.CustomContent = TipCustomContent
Toggletip.Media = TipMedia
Toggletip.Title = TipTitle
Toggletip.Text = TipText
Toggletip.Body = TipBody
Toggletip.ActionBar = TipActionBar
