export const settings = {
  blockAxis: {
    baseline: 'baseline',
    center: 'center',
    end: 'flex-end',
    start: 'flex-start',
    stretch: 'stretch',
  },
  inlineAxis: {
    'space-around': 'space-around',
    center: 'center',
    end: 'flex-end',
    start: 'flex-start',
    'space-between': 'space-between',
  },
}
