/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgNewTab = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='4 4 16 16' {...props}>
    <path
      d='M8.72 9.14A1.04 1.04 0 019.78 8.1l5.06.03a1.04 1.04 0 011.04 1.04l.03 5.06a1.04 1.04 0 11-2.08.01l-.02-2.57-3.9 3.9a1.04 1.04 0 11-1.46-1.48l3.9-3.9h-2.6a1.04 1.04 0 01-1.03-1.05z'
      fill='currentColor'
    />
  </svg>
)
export default SvgNewTab
