/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgIconInform = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 3.12a8.88 8.88 0 1 0 0 17.76 8.88 8.88 0 0 0 0-17.76ZM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm12-5.172c.861 0 1.56.698 1.56 1.56V8.4a1.56 1.56 0 1 1-3.12 0v-.012c0-.862.698-1.56 1.56-1.56Zm0 4.212c.861 0 1.56.698 1.56 1.56v3.6a1.56 1.56 0 1 1-3.12 0v-3.6c0-.862.698-1.56 1.56-1.56Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgIconInform
