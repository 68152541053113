import React from 'react'
import { FocusScope } from 'react-aria'

type Props = React.ComponentProps<typeof FocusScope>

export const SafeFocusScope = (props: Props) => {
  return (
    <div
    /*
      We need wrap the FocusScope in something because it can unmount before calling react-aria's onBlur handler
      If we don't it makes the tests flaky - throws an error because
      document.createTreeWalker is called with null as first argument (parentElement of the first focusable element)
      should be fixed in https://github.com/adobe/react-spectrum/issues/3877, but it somehow preserved the fix :(
    */
    >
      <FocusScope {...props} />
    </div>
  )
}
