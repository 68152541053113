import React from 'react'
import { ClientOnlyPortal } from './ClientOnlyPortal'

type Props = {
  children?: React.ReactNode
}

const RootPortalContext = React.createContext<Element | ShadowRoot | undefined>(undefined)

/** Mounts a portal in a root provided by a DS user with a fallback to document.body */
export const RootPortal = ({ children }: Props) => {
  const root = React.useContext(RootPortalContext)
  return <ClientOnlyPortal element={root ?? document.body}>{children}</ClientOnlyPortal>
}

/** Control where DS mounts root portals */
export const RootPortalProvider = ({
  children,
  root,
}: {
  children?: React.ReactNode
  root: Element | ShadowRoot | undefined
}) => {
  return <RootPortalContext.Provider value={root}>{children}</RootPortalContext.Provider>
}
