/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgSuccess = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6ZM6 1.56C3.54786 1.56 1.56 3.54786 1.56 6C1.56 8.45215 3.54786 10.44 6 10.44C8.45215 10.44 10.44 8.45215 10.44 6C10.44 3.54786 8.45215 1.56 6 1.56ZM8.42431 4.33819C8.72892 4.6428 8.72892 5.13667 8.42431 5.44128L5.87872 7.98686C5.73244 8.13314 5.53405 8.21532 5.32718 8.21532C5.12031 8.21532 4.92191 8.13314 4.77564 7.98686L3.50284 6.71407C3.19823 6.40946 3.19823 5.91559 3.50284 5.61098C3.80745 5.30637 4.30132 5.30637 4.60593 5.61098L5.32718 6.33223L7.32122 4.33819C7.62583 4.03358 8.1197 4.03358 8.42431 4.33819Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgSuccess
