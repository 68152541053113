import React from 'react'
import styled, { css } from 'styled-components'
import { AriaPositionProps } from 'react-aria'
import {
  SvgIconCaution,
  SvgSmartFeature,
  SvgIconInform,
} from '@chilipiper/icons/figma-generated/src/monochrome'
import { Tooltip } from '../tooltip/Tooltip'
import { Icon } from '../../../core-components/icon/Icon'
import { Box } from '../../../core-components'
import { PositioningProps } from '../../types'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { Toggletip } from '../toggletip/Toggletip'
import { UnstyledButton } from '../../../core-components/unstyled-button/UnstyledButton'

type Variant = 'inform' | 'error' | 'smartFeature'

type Props = {
  content: React.ReactNode
  'data-test-id'?: string
  isDisabled?: boolean
  placement?: AriaPositionProps['placement']
  size?: 3 | 4 | 6
  variant?: Variant
} & PositioningProps
export const IconTooltip = ({
  'data-test-id': dataTestId,
  variant = 'inform',
  size = 6,
  isDisabled,
  placement,
  content,
  ...positioningProps
}: Props) => {
  validatePositioningProps(positioningProps, 'IconTooltip')
  return (
    <Tooltip
      isDisabled={isDisabled}
      content={content}
      placement={placement}
      data-test-id={dataTestId ? `${dataTestId}-tooltip` : undefined}
    >
      <Tooltip.NonFocusableTrigger {...positioningProps}>
        <StyledBox
          borderRadius={8}
          p={size === 6 ? 1 : 0}
          data-test-id={dataTestId}
          variant={variant}
          isDisabled={isDisabled}
        >
          <StyledIcon
            color={getColor(variant)}
            icon={getIcon(variant)}
            size={size === 3 ? size : 4}
          />
        </StyledBox>
      </Tooltip.NonFocusableTrigger>
    </Tooltip>
  )
}

export const IconToggletip = ({
  'data-test-id': dataTestId,
  variant = 'inform',
  size = 6,
  isDisabled,
  placement,
  content,
  ...positioningProps
}: Props) => {
  validatePositioningProps(positioningProps, 'IconToggletip')
  return (
    <Toggletip
      isDisabled={isDisabled}
      content={content}
      placement={placement}
      data-test-id={dataTestId ? `${dataTestId}-toggletip` : undefined}
    >
      {triggerProps => (
        <UnstyledButton ariaProps={triggerProps} {...positioningProps}>
          <StyledBox
            borderRadius={8}
            p={size === 6 ? 1 : 0}
            data-test-id={dataTestId}
            variant={variant}
            isDisabled={isDisabled}
          >
            <StyledIcon color={getColor(variant)} icon={getIcon(variant)} />
          </StyledBox>
        </UnstyledButton>
      )}
    </Toggletip>
  )
}

const getColor = (variant: Variant) => {
  switch (variant) {
    case 'inform':
      return 'icon/indicator'
    case 'error':
      return 'icon/error'
    case 'smartFeature':
      return 'icon/cp-logo'
  }
}
const getHoverBgColor = (variant: Variant) => {
  switch (variant) {
    case 'inform':
      return 'bg/feedback-inform'
    case 'error':
      return 'bg/feedback-caution'
    case 'smartFeature':
      return 'bg/feedback-neutral'
  }
}
const getIcon = (variant: Variant) => {
  switch (variant) {
    case 'inform':
      return SvgIconInform
    case 'error':
      return SvgIconCaution
    case 'smartFeature':
      return SvgSmartFeature
  }
}

const StyledIcon = styled(Icon)``
const StyledBox = styled(Box)<{ isDisabled?: boolean; variant: Variant }>`
  cursor: default;
  :hover {
    background: ${({ theme, variant, isDisabled }) => {
      if (!isDisabled) {
        return theme.colors[getHoverBgColor(variant)]
      }
    }};
  }
  ${({ variant, isDisabled }) => {
    if (isDisabled) {
      return css`
        cursor: default;
      `
    }
    if (variant === 'inform') {
      return css`
        :hover ${StyledIcon} {
          color: ${({ theme }) => theme.colors['icon/inform']};
        }
      `
    }
  }}
`
