import React from 'react'
import { Box, BoxType } from '../box'

type Props = {
  icon: React.ComponentType
  size?: 3 | 4 | 5 | 6
} & Omit<BoxType, 'size'>

export const Icon = ({ size = 4, icon: Icon, ...props }: Props) => {
  return (
    <Box w={size} h={size} {...props}>
      <Icon />
    </Box>
  )
}
