import { useMemo, useState } from 'react'
import {
  BaseModalLayoutCustomizableProps,
  ModalFlowActions,
  ModalFlowContextActions,
} from '../types'
import { PromiseResolutionMethods } from './ModalFlowProvider'

export type ModalFlowControllersParams = ModalFlowActions &
  ModalFlowContextActions &
  PromiseResolutionMethods &
  Pick<BaseModalLayoutCustomizableProps, 'shouldCloseOnConfirm'>

export const useModalFlowControllers = ({
  shouldCloseOnConfirm = true,
  onCancelClick,
  onConfirmClick,
  resolve,
  reject,
  onClose,
}: ModalFlowControllersParams) => {
  const [confirmActionIsRunning, setConfirmActionIsRunning] = useState(false)

  const handleConfirm = useMemo(() => {
    if (!onConfirmClick) {
      return
    }
    return () => {
      setConfirmActionIsRunning(true)
      const asynOnConfirm = async () => onConfirmClick()
      return asynOnConfirm()
        .then(resolve)
        .then(shouldCloseOnConfirm ? onClose : undefined)
        .finally(() => setConfirmActionIsRunning(false))
    }
  }, [onClose, onConfirmClick, resolve, shouldCloseOnConfirm])

  const handleCancel = useMemo(() => {
    const asyncOnCancel = async () => onCancelClick?.()
    return () => asyncOnCancel().then(onClose).then(reject)
  }, [onClose, onCancelClick, reject])

  return {
    onClose,
    onCancelClick: handleCancel,
    onConfirmClick: handleConfirm,
    confirmActionIsRunning,
  }
}
