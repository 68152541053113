import React from 'react'
import styled from 'styled-components'
import { SvgIosSpinner } from '@chilipiper/icons/src/IosSpinner'
import { Icon } from '../../../../old/icon'
import { Box, ScreenReaderOnly } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { PositioningProps } from '../../types'

type Props = PositioningProps & { 'data-test-id'?: string }

export const Spinner = ({ 'data-test-id': dataTestId, ...positioningProps }: Props) => {
  validatePositioningProps(positioningProps, 'Spinner')

  return (
    <Box data-test-id={dataTestId} w='min-content' {...positioningProps}>
      <AnimatedIcon color='bg/spinner-loader' icon={SvgIosSpinner} aria-hidden={true} />
      <ScreenReaderOnly>Loading</ScreenReaderOnly>
    </Box>
  )
}

const AnimatedIcon = styled(Icon)`
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    100% {
      transform: rotate(1turn);
    }
  }
`
