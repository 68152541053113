export const remToPx = (remContainingString: string) => {
  const remValues = remContainingString.match(/(\d*\.?\d+)rem/g)
  if (!remValues) {
    return remContainingString
  }
  let pxContainingString = remContainingString
  remValues.forEach(remValue => {
    const pxValue = parseFloat(remValue) * 16
    pxContainingString = pxContainingString.replace(remValue, `${pxValue}px`)
  })
  return pxContainingString
}
