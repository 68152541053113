import { FC, ComponentProps } from 'react'
import CSS from 'csstype'
import invariant from 'tiny-invariant'
import styled from 'styled-components'
import {
  border,
  BorderProps,
  BorderRadiusProps,
  compose,
  flexbox,
  FlexboxProps,
  FontSizeProps,
  FontStyleProps,
  FontWeightProps,
  get,
  grid,
  GridProps,
  layout,
  LayoutProps,
  LetterSpacingProps,
  LineHeightProps,
  position,
  PositionProps,
  ResponsiveValue,
  Scale,
  shadow,
  space,
  SpaceProps,
  system,
  TextAlignProps,
  TextShadowProps,
  TLengthStyledSystem,
  typography,
} from 'styled-system'
import { contextualBorderPalette } from '../../theme/contextualBorderPalette'
import { Radius, Theme, ThemeColors, ThemeShadows } from '../../theme'
import {
  StyledProps,
  ThemeBlockAxis,
  ThemeFontFamily,
  ThemeFontSizes,
  ThemeFontWeights,
  ThemeInlineAxis,
  ThemeLineHeights,
  ThemeOpacities,
} from '../../theme/types'

export type BoxType = StyledProps &
  LayoutProps<Theme> &
  SpaceProps<Theme, CSS.Property.Margin<TLengthStyledSystem>> &
  Omit<FlexboxProps<Theme>, 'alignItems' | 'justifyContent'> &
  Omit<GridProps<Theme>, 'gridGap' | 'gridColumnGap' | 'gridRowGap'> &
  Omit<
    BorderProps<Theme>,
    | 'borderRadius'
    | 'borderBottomRightRadius'
    | 'borderBottomLeftRadius'
    | 'borderTopLeftRadius'
    | 'borderTopRightRadius'
  > &
  BorderRadiusProps<Theme, Radius | CSS.Property.BorderRadius<TLengthStyledSystem>> &
  TextShadowProps<Theme> &
  Omit<PositionProps<Theme>, 'position'> &
  FontSizeProps<Theme, ThemeFontSizes | CSS.Property.FontSize<TLengthStyledSystem>> &
  FontWeightProps<Theme, ThemeFontWeights | CSS.Property.FontWeight> &
  LetterSpacingProps<Theme, CSS.Property.LetterSpacing<TLengthStyledSystem>> &
  LineHeightProps<Theme, ThemeLineHeights | CSS.Property.LineHeight<TLengthStyledSystem>> &
  FontStyleProps<Theme> &
  TextAlignProps<Theme> & {
    alignItems?: ResponsiveValue<ThemeBlockAxis | CSS.Property.AlignItems, Theme>
    bg?: ResponsiveValue<ThemeColors, Theme>
    boxShadow?: ThemeShadows
    color?: ResponsiveValue<ThemeColors, Theme>
    columnGap?: CSS.Property.Gap<TLengthStyledSystem>
    cursor?: CSS.Property.Cursor
    fontFamily?: ResponsiveValue<ThemeFontFamily | CSS.Property.FontFamily, Theme>
    gap?: CSS.Property.Gap<TLengthStyledSystem>
    h?: ResponsiveValue<CSS.Property.Height<TLengthStyledSystem>, Theme>
    justifyContent?: ResponsiveValue<ThemeInlineAxis | CSS.Property.JustifyContent, Theme>
    opacity?: ResponsiveValue<ThemeOpacities | CSS.Property.Opacity>
    position?: ResponsiveValue<CSS.Property.Position, Theme>
    rowGap?: CSS.Property.Gap<TLengthStyledSystem>
    w?: ResponsiveValue<CSS.Property.Width<TLengthStyledSystem>, Theme>
  }

const isNumber = (n: unknown) => typeof n === 'number' && !isNaN(n)
const getSize = (n: any, scale?: Scale) => get(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + '%')

export type BoxRootComponent = FC<Omit<ComponentProps<'div'>, keyof BoxType>>

/** Trouble with position or boxShadow? See comment below */
export const Box = styled<BoxRootComponent>('div' as unknown as BoxRootComponent)<BoxType>(
  compose(layout, space, flexbox, grid, border, shadow, position, typography),
  system({
    opacity: {
      property: 'opacity',
      scale: 'opacities',
    },
    w: {
      property: 'width',
      scale: 'sizes',
      transform: getSize,
    },
    h: {
      property: 'height',
      scale: 'sizes',
      transform: getSize,
    },
    gap: {
      property: 'gap',
      scale: 'sizes',
      transform: getSize,
    },
    rowGap: {
      property: 'rowGap',
      scale: 'sizes',
      transform: getSize,
    },
    columnGap: {
      property: 'columnGap',
      scale: 'sizes',
      transform: getSize,
    },
    justifyContent: {
      property: 'justifyContent',
      scale: 'inlineAxis', // we are mapping start to flex-start for example
    },
    alignItems: {
      property: 'alignItems',
      scale: 'blockAxis', // we are mapping start to flex-start for example
    },
    bg: {
      property: 'background', // the default was 'backgroundColor' but we need to use gradients too
      scale: 'colors',
    },
    color: {
      property: 'color',
      scale: 'colors',
    },
  }),
  ({ cursor }) => ({ cursor }),
  props => {
    if (props.boxShadow && props.boxShadow in contextualBorderPalette) {
      const contextualAllowedPositions = ['sticky', 'absolute', 'fixed', 'relative']

      /**
       * Because we need a workaround for our shadows (see
       * https://floatingapps.atlassian.net/browse/CPP-7243), you can't mix position other than
       * sticky, absolute, fixed or relative with boxShadow.
       */
      invariant(
        props.position === undefined ||
          (typeof props.position === 'string' &&
            contextualAllowedPositions.includes(props.position)),
        `Cannot use position "${
          props.position
        }" with contextual borders! You can: \n\n1 - Wrap your component with a styled div instead and position the wrapper: \n <div position='${
          props.position
        }'>{Your component Here}</div> \n\n2 - Use one of the allowed positions: ${contextualAllowedPositions.join(
          ' | '
        )}`
      )

      return {
        position: props.position ?? 'relative',
        boxShadow: 'none !important',
        '::after': {
          position: 'absolute',
          content: '""',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          boxShadow: props.theme.shadows[props.boxShadow],
          borderRadius: 'inherit',
        },
      }
    }
  }
)

/** Trouble with position or boxShadow? See {@link Box} */
export const Flex = styled(Box)`
  display: flex;
`

/** Trouble with position or boxShadow? See {@link Box} */
export const Grid = styled(Box)`
  display: grid;
`
