import { ThemeColors, ThemeShadows } from '../../../theme'

export type BaseLinkButtonVariant = 'major' | 'minor' | 'caution' | 'onColor'

export const baseLinkButtonVariantProps: Record<
  BaseLinkButtonVariant,
  {
    color: ThemeColors
    iconColor: ThemeColors
    underlineShadow: ThemeShadows
  }
> = {
  major: {
    color: 'text/action-major',
    underlineShadow: 'border/action-underline-major',
    iconColor: 'icon/action-major',
  },
  minor: {
    color: 'text/action-minor',
    underlineShadow: 'border/action-underline-minor',
    iconColor: 'icon/action-minor',
  },
  caution: {
    color: 'text/action-caution',
    underlineShadow: 'border/action-underline-caution',
    iconColor: 'icon/action-caution',
  },
  onColor: {
    color: 'text/action-oncolor',
    underlineShadow: 'border/action-underline-oncolor',
    iconColor: 'icon/on-color',
  },
}

export const baseLinkButtonProps = {
  display: 'block',
  m: 0,
  border: 'none',
  p: 0,
  // To keep consistent with button - https://html.spec.whatwg.org/multipage/rendering.html#button-layout
  // > If the computed value of 'inline-size' is 'auto', then the used value is the fit-content inline size
  w: 'fit-content',
  bg: 'transparent',
} as const
