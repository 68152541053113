import React, { useId, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { AvatarStatus } from '../avatar-status/AvatarStatus'
import type { AvatarStatusVariant } from '../avatar-status/AvatarStatus'
import { Box } from '../../../core-components'

type Props = {
  id: string
  image?: string
  name: string
} & (
  | {
      size: 4 | 5 | 6 | 7
      status?: AvatarStatusVariant
    }
  | { size: 3 | 8 | 9 | 10 | 11 | 12 | 13; status?: never }
)

export const Avatar = ({ image, name, id, ...restProps }: Props) => {
  const componentId = useId()
  const theme = useTheme()
  const resolvedSize = theme.sizes[restProps.size]
  const statusSize = restProps.size < 6 ? 8 : 10
  return (
    <Box position='relative' flexShrink={0}>
      <Box as='svg' role='none' w={resolvedSize} h={resolvedSize}>
        <mask id={componentId}>
          <circle fill='white' cx='50%' cy='50%' r='50%'></circle>
          {'status' in restProps && restProps.status !== undefined && (
            <circle
              fill='black'
              transform={`translate(-${statusSize / 2}, -${statusSize / 2})`}
              cx='100%'
              cy='100%'
              r={statusSize / 2 + 1}
            ></circle>
          )}
        </mask>
        <BaseAvatar
          image={image}
          id={id}
          name={name}
          mask={`url(#${componentId})`}
          size={restProps.size}
        />
      </Box>
      {'status' in restProps && restProps.status !== undefined && (
        <AvatarStatus
          size={statusSize}
          position='absolute'
          bottom={0}
          right={0}
          variant={restProps.status}
        />
      )}
    </Box>
  )
}

const textSizeByAvatarSize = {
  3: 6,
  4: 8,
  5: 8,
  6: 10,
  7: 12,
  8: 16,
  9: 16,
  10: 20,
  11: 22,
  12: 24,
  13: 28,
}

export const BaseAvatar = ({
  image,
  id,
  name,
  mask,
  size,
}: {
  id: string
  image?: string
  mask?: string
  name: string
  size: 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13
}) => {
  const [imageVisible, setImageVisible] = useState(true)
  const { colors } = useTheme()
  const color = seededRandom(
    [
      colors['blue-light-100'],
      colors['green-light-100'],
      colors['purple-light-100'],
      colors['yellow-light-100'],
      colors['orange-light-100'],
    ],
    id
  )

  return (
    <g mask={mask}>
      <circle cx='50%' cy='50%' r='50%' fill={color} />
      <text
        aria-hidden
        x='50%'
        y='50%'
        textAnchor='middle'
        dominantBaseline='central'
        fill={colors['text/on-color']}
        fontSize={textSizeByAvatarSize[size]}
        fontWeight={600}
      >
        {name.charAt(0).toUpperCase()}
      </text>
      {image && imageVisible && (
        // use foreignObject to allow loading images from other origins without CORS headers
        <foreignObject width='100%' height='100%'>
          <Image
            src={image}
            alt=''
            onError={() => setImageVisible(false)}
            onLoad={() => setImageVisible(true)}
          />
        </foreignObject>
      )}
    </g>
  )
}

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

/**
 * Hashes a string to a non-negative number modified version of
 * {@link https://stackoverflow.com/a/7616484/112731}
 */
const hashCode = (str: string) => {
  const hash = [...str].reduce((hash, char) => ((hash << 5) - hash + char.charCodeAt(0)) | 0, 0)
  return hash < 0 ? ~hash : hash
}

/** Returns a random element from the array which is always the same for the same seed */
const seededRandom = (options: string[], seed: string) => {
  return options[hashCode(seed) % options.length]
}
