import React, { useId } from 'react'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { PositioningProps } from '../../types'
import { Box, Flex, ScreenReaderOnly, Text } from '../../../core-components'
import { BaseAvatar } from '../avatar/Avatar'

type Size = 4 | 5 | 6 | 7 | 8

type Props = {
  size: Size
  users: ReadonlyArray<{
    id: string
    image?: string
    name: string
  }>
} & PositioningProps

const textSizeByAvatarSize = {
  4: 8,
  5: 9,
  6: 10,
  7: 12,
  8: 12,
}
export const AvatarGroup = ({ users, size, ...positioningProps }: Props) => {
  validatePositioningProps(positioningProps, 'AvatarGroup')

  const moreCount = users.length - 2
  const hasMore = moreCount > 1
  const usersToDisplay = hasMore ? users.slice(0, 2) : users
  return (
    <Flex flexShrink={0} {...positioningProps}>
      <ScreenReaderOnly>{users.length} users</ScreenReaderOnly>
      {usersToDisplay.map(({ image, name, id }, index) => {
        const isLast = index === users.length - 1
        return (
          <CutOutImage
            key={id}
            image={image}
            name={name}
            isCut={!isLast || hasMore}
            id={id}
            size={size}
          />
        )
      })}
      {hasMore && (
        <Flex
          borderRadius='full'
          bg='bg/container-highlight'
          alignItems='center'
          justifyContent='center'
          w={size}
          h={size}
        >
          <Text
            color='text/detail-moderate'
            fontSize={textSizeByAvatarSize[size]}
            fontWeight={600}
            aria-hidden
          >
            +{moreCount}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const pxSizeByAvatarSize = {
  4: 16,
  5: 20,
  6: 24,
  7: 32,
  8: 40,
}

const overlapByAvatarSize = {
  4: 2,
  5: 2.5,
  6: 3,
  7: 4,
  8: 5,
}

const getCutoutData = (size: Size) => {
  const pxSize = pxSizeByAvatarSize[size]
  const overlap = overlapByAvatarSize[size]
  const cutoutRadius = pxSize / 2 + overlap
  const cutoutCenter = pxSize * 1.5 - overlap
  return { cutoutRadius, cutoutCenter, overlap }
}

const CutOutImage = ({
  image,
  isCut,
  id,
  name,
  size,
}: {
  id: string
  image?: string
  isCut: boolean
  name: string
  size: Size
}) => {
  const componentId = useId()
  const { cutoutCenter, cutoutRadius, overlap } = getCutoutData(size)

  return (
    <Box as='svg' role='none' w={size} h={size} mr={isCut ? `-${overlap}px` : 0}>
      <mask id={componentId}>
        <circle fill='white' cx='50%' cy='50%' r='50%'></circle>
        {isCut && <circle fill='black' cx={cutoutCenter} cy='50%' r={cutoutRadius}></circle>}
      </mask>
      <BaseAvatar id={id} name={name} image={image} mask={`url(#${componentId})`} size={size} />
    </Box>
  )
}
