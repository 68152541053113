import React, { Children } from 'react'
import { typeValidator } from '@chilipiper/utils'
import { AriaProps } from './types'

export const isMac = () => window && /^Mac/i.test(window.navigator.platform)
export const flattenChildren: typeof Children.toArray = (...args) => {
  const childrenArray = Children.toArray(...args)
  return childrenArray.reduce((flatChildren: ReturnType<typeof Children.toArray>, child) => {
    if (typeValidator(React.Fragment)(child)) {
      return flatChildren.concat(flattenChildren(child.props.children))
    }
    flatChildren.push(child)
    return flatChildren
  }, [])
}

export const splitAriaProps = <Type extends keyof HTMLElementTagNameMap = 'button'>(
  props: AriaProps<Type> | undefined
) => {
  const {
    elementRef,
    onPointerEnter,
    onPointerLeave,
    onMouseLeave,
    onMouseEnter,
    onTouchStart,
    ...reactAriaProps
  } = props ?? {}

  return {
    elementRef,
    domProps: { onPointerEnter, onPointerLeave, onMouseLeave, onMouseEnter, onTouchStart },
    reactAriaProps,
  }
}
