import React from 'react'
import { SvgX } from '@chilipiper/icons/src/design-system'
import { SvgCheckMark } from '@chilipiper/icons'
import { Icon } from '../../../../old/icon/Icon'
import { Box } from '../../../core-components'

export type AvatarStatusVariant = 'online' | 'offline' | 'inviteAccepted' | 'inviteDenied'

type Props = {
  size: number
  variant: AvatarStatusVariant
} & React.ComponentPropsWithoutRef<typeof Box>

export const AvatarStatus = ({ size, variant, ...boxProps }: Props) => {
  const commonProps = {
    w: `${size}px`,
    h: `${size}px`,
    borderRadius: 'full',
    ...boxProps,
  } as const
  switch (variant) {
    case 'online': {
      return <Box {...commonProps} bg='icon/success' />
    }
    case 'offline': {
      return <Box {...commonProps} bg='icon/error' />
    }
    case 'inviteDenied': {
      return (
        <Box {...commonProps} bg='icon/error'>
          <Icon color='icon/on-color' w='100%' h='100%' icon={SvgX} />
        </Box>
      )
    }
    case 'inviteAccepted': {
      return (
        <Box {...commonProps} bg='icon/success'>
          <Icon color='icon/on-color' w='100%' h='100%' icon={SvgCheckMark} />
        </Box>
      )
    }
  }
  return null
}
