import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import { useToastState } from '@react-stately/toast'
import { createContextWithHook } from '@chilipiper/utils'
import { AlertBannerType, ToastType } from './types'
import { ToastRegion } from './ToastRegion'

type State = {
  showAlertBanner: (alertBanner: Omit<AlertBannerType, 'INTERNAL_type'>, duration?: number) => void
  showToast: (toast: Omit<ToastType, 'INTERNAL_type'>, duration?: number) => void
}

const { useToastContext, ToastBaseProvider } = createContextWithHook<State>()('Toast')

export const useShowToast = () => useToastContext().showToast
export const useShowAlertBanner = () => useToastContext().showAlertBanner

type Props = {
  children: ReactNode
}

export const ToastProvider = ({ children }: Props) => {
  const state = useToastState<ToastType | AlertBannerType>({
    maxVisibleToasts: 5,
    hasExitAnimation: true,
  })
  const priority = useRef(0)
  // If not added as ref we would need to put state in the useCallback dependency array which can cause infinite loops
  const add = useRef(state.add)
  useEffect(() => {
    add.current = state.add
  }, [state])

  const showToast = useCallback((props: Omit<ToastType, 'INTERNAL_type'>, duration = 3000) => {
    add.current(
      { ...props, INTERNAL_type: 'toast' },
      { timeout: duration, priority: priority.current }
    )
    priority.current += 1
  }, [])
  const showAlertBanner = useCallback(
    (props: Omit<AlertBannerType, 'INTERNAL_type'>, duration = 3000) => {
      add.current(
        { ...props, INTERNAL_type: 'alert', isDismissable: props.isDismissable },
        { timeout: props.isDismissable ? duration : undefined, priority: priority.current }
      )
      priority.current += 1
    },
    []
  )

  const value = useMemo(() => ({ showToast, showAlertBanner }), [showToast, showAlertBanner])

  return (
    <ToastBaseProvider value={value}>
      {children}
      {state.visibleToasts.length > 0 && <ToastRegion state={state} />}
    </ToastBaseProvider>
  )
}
