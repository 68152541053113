import React, { HTMLProps } from 'react'
import styled from 'styled-components'
import { Flex, BoxType } from '../../new/core-components/box'

type Svg = {
  fill?: 'currentColor' | 'none' | string
  stroke?: 'currentColor' | 'none' | string
  strokeWidth?: number
}

type ContainerProps = Omit<HTMLProps<HTMLDivElement>, keyof BoxType> & {
  pointer?: boolean
  svg?: Svg
}

type Props = ContainerProps &
  BoxType & {
    icon: React.ComponentType
  }

const Container = styled(Flex)<ContainerProps>`
  cursor: ${props => (props.pointer ? 'pointer' : 'inherit')};

  > svg {
    width: 100%;
    height: 100%;
    fill: ${props => props.svg?.fill || 'none'};
    stroke: ${props => (props.svg?.stroke ? `${props.svg.stroke} !important` : 'none')};
    stroke-width: ${props => props.svg?.strokeWidth};
  }
`

export const Icon = ({
  icon: Icon,
  pointer,
  svg = {
    fill: 'currentColor',
    stroke: 'currentColor',
    strokeWidth: 0,
  },
  ...others
}: Props) => {
  return (
    <Container
      alignItems='center'
      justifyContent='center'
      pointer={pointer}
      w={4}
      h={4}
      svg={svg}
      {...others}
    >
      <Icon />
    </Container>
  )
}
