import { palette } from './palette'

export const contextualBorderPalette = {
  'border/row-unselected': `inset 0px -1px 0px ${palette['neutral-dark-4']}`,
  'border/form-item-focused': `inset 0px 0px 0px 1px ${palette['neutral-dark-4']}, inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/form-item-nested-focused': `inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/cell-focused-last-child': `inset 0px -2px 0px ${palette['teal-dark-100']}`,
  'border/cell-focused': `inset -1px 0px 0px ${palette['neutral-dark-4']}, inset 0px -2px 0px ${palette['teal-dark-100']}`,
  'border/cell-action-minor-focused-last-child': `inset 0px -2px 0px ${palette['neutral-dark-100']}`,
  'border/cell-action-minor-focused': `inset -1px 0px 0px ${palette['neutral-dark-4']}, inset 0px -2px 0px ${palette['neutral-dark-100']}`,
  'border/card-option-selected': `0px 0px 0px 4px ${palette['purple-dark-12']}, inset 0px 0px 0px 1px ${palette['purple-dark-100']}`,
  'border/card-highlighted-inform': `0px 0px 0px 4px ${palette['blue-dark-12']}, inset 0px 0px 0px 1px ${palette['blue-dark-100']}`,
  'border/section-selected': `inset 2px 0px 0px ${palette['purple-dark-100']}`,
  'border/body-modal': `inset 0px -1px 0px 0px ${palette[`neutral-dark-8`]}`,
  'border/action-underline-caution': `inset 0px -2px 0px ${palette['red-dark-12']}`,
  'border/action-underline-caution-hover': `inset 0px -2px 0px ${palette['red-dark-100']}`,
  'border/action-underline-oncolor': `inset 0px -2px 0px rgba(255, 255, 255, 0.16)}`,
  'border/action-underline-major': `inset 0px -2px 0px ${palette['purple-dark-12']}`,
  'border/action-underline-major-hover': `inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/action-underline-minor': `inset 0px -2px 0px ${palette['neutral-dark-8']}`,
  'border/action-underline-minor-hover': `inset 0px -2px 0px ${palette['neutral-dark-64']}`,
  'border/action-underline-warning': `inset 0px -2px 0px ${palette['yellow-dark-12']}`,
  'border/action-underline-inform': `inset 0px -2px 0px ${palette['blue-dark-12']}`,
  'border/action-underline-moderate': `inset 0px -2px 0px ${palette['teal-dark-12']}`,
  'border/action-focused-warning': `inset 0px -2px 0px ${palette['yellow-light-100']}`,
  'border/action-focused-moderate': `inset 0px -2px 0px ${palette['neutral-dark-100']}`,
  'border/action-focused-minor': `inset 0px -2px 0px ${palette['neutral-dark-100']}`,
  'border/action-focused-major': `inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/action-focused-caution': `inset 0px -2px 0px ${palette['red-dark-100']}`,
  'border/focus-indicator': `inset 0px 0px 0px 2px ${palette['neutral-dark-24']}`,
  'border/feedback-caution': `inset 0px 0px 0px 1px ${palette['red-dark-20']}`,
  'border/feedback-success': `inset 0px 0px 0px 1px ${palette['green-dark-20']}`,
  'border/focus-indicator-item-selected': `inset 0px 0px 0px 2px ${palette['neutral-dark-24']}, inset 2px 0 0 ${palette['purple-dark-100']}`,
  'border/select-indicator-unselected': `inset 0px 0px 0px 2px ${palette['neutral-dark-32']}`,
  'border/select-indicator-disabled': `inset 0px 0px 0px 2px ${palette['neutral-dark-12']}`,
  'border/radio-section-selected': `inset 2px 0px 0px ${palette['purple-dark-100']}, inset 0px -1px 0px ${palette['neutral-dark-16']}`,
  'border/row-selected': `inset 0px -1px 0px ${palette['neutral-dark-16']}`,
  'border/row': `inset 0px -1px 0px ${palette['neutral-dark-8']}`,
  'border/row-top': `inset 0px 1px 0px ${palette['neutral-dark-8']}`,
  'border/row-oncolor': `inset 0px -1px 0px ${palette['neutral-light-8']};`,
  'border/popover': `0 8px 20px -2px ${palette['neutral-dark-12']}, inset 0px 0px 0px 1px ${palette['neutral-dark-8']}`,
  'border/page-divider-section': `inset 0px -4px 0px ${palette['neutral-dark-4']}`,
  'border/on-major': `inset 0px 0px 0px 1px ${palette['neutral-dark-8']}`,
  'border/list-divider': `inset 0px -1px 0px ${palette['neutral-light-32']}`,
  'border/list-item': `inset 0px -1px 0px ${palette['neutral-dark-4']}`,
  'border/list-item-selected': `inset 0px -1px 0px ${palette['neutral-dark-16']}`,
  'border/list-item-unselected': `inset 0px -1px 0px ${palette['neutral-dark-4']}`,
  'border/list-item-major-selected': `inset 2px 0 0 ${palette['purple-dark-100']}, inset 0 -1px 0 ${palette['neutral-dark-16']}`,
  'border/list-item-minor-selected': `inset 2px 0 0 ${palette['neutral-dark-100']}, inset 0 -1px 0 ${palette['neutral-dark-16']}`,
  'border/content-overflow': `inset 0px -2px 0px ${palette['neutral-dark-16']}`,
  'border/content-divider-subsection': `inset 0px -1px 0px ${palette['neutral-dark-8']}`,
  'border/cascade-column': `inset -1px 0px 0px ${palette['neutral-dark-16']}`,
  'border/cell-sticky-left': `4px 0px 0px ${palette['neutral-dark-4']}, inset -1px 0px 0px ${palette['neutral-dark-16']}`,
  'border/cell-left': `inset 1px 0px 0px ${palette['neutral-dark-8']}`,
  'border/cell': `inset -1px 0px 0px ${palette['neutral-dark-8']}`,
  'border/tab-item-selected': `inset 0px -2px 0px 0px ${palette['orange-dark-100']}`,
  'border/horizontal-tab-item-minor-selected': `inset 0px -2px 0px ${palette['neutral-dark-64']};`,
  'border/tab-item-hovered': `inset 0px -2px 0px 0px ${palette['neutral-dark-32']}`,
  'border/tree-item-selected': `inset 0px -2px 0px ${palette['purple-dark-100']}`,
  'border/tree-item-unselected': `inset 0px -1px 0px ${palette['neutral-dark-8']}`,
  'border/chat-form-item-error-focused': palette['red-dark-48'],
  'border/chat-form-item-focused': palette['neutral-dark-12'],
  'border/chat-content-overflow': `inset 0px -2px 0px ${palette['neutral-dark-16']}`,
  'border/select-item': `inset 0 0 0 2px ${palette['neutral-dark-24']}`,
  'border/select-item-disabled': `inset 0 0 0 2px ${palette['neutral-dark-12']}`,
  'border/container-hover': `0px 4px 16px 0px ${palette['neutral-dark-8']}, 0px 0px 1px 0px ${palette['neutral-dark-24']}, 0px 0px 0px 1px ${palette['neutral-dark-24']}`,
  'border/drop-line': `0px 0px 4px ${palette['blue-dark-100']}`,
  'border/card-highlighted-success': `0px 0px 0px 4px ${palette['green-dark-12']}, 0px 0px 0px 1px ${palette['green-dark-100']} inset`,

  'border/node': `0px 12px 16px -10px ${palette['neutral-dark-12']}, 0px 0px 0px 1px ${palette['neutral-dark-8']} inset`,
  'border/node-hovered': `0px 12px 16px -10px ${palette['neutral-dark-12']}, 0px 0px 0px 2px ${palette['neutral-dark-24']} inset`,
  'border/node-selected': `0px 0px 0px 4px ${palette['purple-dark-12']}, 0px 0px 0px 1px ${palette['purple-dark-100']} inset`,
  'border/node-subcontainer': `0px -1px 3px -1px hsla(0, 0%, 0%, 0.1)`,
}
