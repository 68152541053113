// Forked from https://github.com/adobe/react-spectrum/blob/f9143723fe44a1c2e03eaec93f1cc2dba4e020cf/packages/%40react-stately/menu/src/useMenuTriggerState.ts to add initial expanded and onExpanded
import { useState } from 'react'
import { FocusStrategy, Key } from '@react-types/shared'
import { MenuTriggerProps } from '@react-types/menu'
import { OverlayTriggerState, useOverlayTriggerState } from '@react-stately/overlays'
import { useControlledState } from '@react-stately/utils'

interface MenuTriggerState extends OverlayTriggerState {
  /** Controls which item will be auto focused when the menu opens. */
  readonly focusStrategy: FocusStrategy

  /** Opens the menu. */
  open(focusStrategy?: FocusStrategy | null): void

  /** Toggles the menu. */
  toggle(focusStrategy?: FocusStrategy | null): void
}

export interface RootMenuTriggerState extends MenuTriggerState {
  /** Closes a specific submenu tied to a specific menu item at a specific level. */
  closeSubmenu: (triggerKey: Key, level: number) => void

  /**
   * An array of open submenu trigger keys within the menu tree. The index of key within array
   * matches the submenu level in the tree.
   */
  expandedKeysStack: Key[]

  /** Opens a specific submenu tied to a specific menu item at a specific level. */
  openSubmenu: (triggerKey: Key, level: number) => void

  /** Closes the menu and all submenus in the menu tree. */
  close: () => void
}

type MenuTriggerPropsExtended = MenuTriggerProps & {
  expandedItem?: string
  onExpandedChange?: (expanded?: string | undefined) => void
}

/**
 * Manages state for a menu trigger. Tracks whether the menu is currently open, and controls which
 * item will receive focus when it opens. Also tracks the open submenus within the menu tree via
 * their trigger keys.
 */
export const useMenuTriggerState = (props: MenuTriggerPropsExtended): RootMenuTriggerState => {
  const overlayTriggerState = useOverlayTriggerState({ isOpen: true })
  const [focusStrategy, setFocusStrategy] = useState<FocusStrategy | null>(null)
  const initialState = (() => {
    if (props.expandedItem === undefined) {
      if (props.onExpandedChange === undefined) {
        return undefined
      }
      return []
    }
    return [props.expandedItem]
  })()
  const [expandedKeysStack, setExpandedKeysStack] = useControlledState<Key[]>(
    initialState,
    [],
    stack => props.onExpandedChange?.(stack[stack.length - 1] as string | undefined)
  )

  const closeAll = () => {
    setExpandedKeysStack([])
    overlayTriggerState.close()
  }

  const openSubmenu = (triggerKey: Key, level: number) => {
    if (expandedKeysStack.length <= level) {
      setExpandedKeysStack([...expandedKeysStack.slice(0, level), triggerKey])
    }
  }

  const closeSubmenu = (triggerKey: Key, level: number) => {
    const key = expandedKeysStack[level]
    if (key === triggerKey) {
      setExpandedKeysStack(expandedKeysStack.slice(0, level))
    }
  }

  return {
    focusStrategy: focusStrategy as FocusStrategy,
    ...overlayTriggerState,
    open(focusStrategy: FocusStrategy | null = null) {
      setFocusStrategy(focusStrategy)
      overlayTriggerState.open()
    },
    toggle(focusStrategy: FocusStrategy | null = null) {
      setFocusStrategy(focusStrategy)
      overlayTriggerState.toggle()
    },
    close() {
      closeAll()
    },
    expandedKeysStack: expandedKeysStack,
    openSubmenu: openSubmenu,
    closeSubmenu: closeSubmenu,
  }
}
