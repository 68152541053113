import { PositioningProps } from '../components/types'

export type PositionPropName = keyof PositioningProps

const arrayOfAll =
  <T>() =>
  <U extends T[]>(array: U & ([T] extends [U[number]] ? unknown : 'Invalid') & { 0: T }) =>
    array

const arrayOfAllPositionPropNames = arrayOfAll<PositionPropName>()

const positionPropNamesSet = new Set<PositionPropName>(
  arrayOfAllPositionPropNames(['m', 'mb', 'ml', 'mr', 'mt', 'mx', 'my'])
)

export const validatePositioningProps = (
  positioningProps: Record<string, unknown>,
  componentName?: string
) => {
  Object.keys(positioningProps).forEach(propName => {
    if (!positionPropNamesSet.has(propName as PositionPropName)) {
      throw new Error(
        `Invalid positioning prop '${propName}' provided${
          componentName ? ` to ${componentName}` : ''
        }.`
      )
    }
  })
}
