import React from 'react'
import { mergeProps } from 'react-aria'
import { ToastState } from '@react-stately/toast'
import { useToastRegion } from '@react-aria/toast'
import styled from 'styled-components'
import { RootPortal, Flex } from '../../../core-components'
import { useFocusVisible } from '../../hooks'
import { AlertBannerType, ToastType } from './types'
import { Toast } from './Toast'

interface ToastRegionProps {
  state: ToastState<ToastType | AlertBannerType>
}

export const ToastRegion = ({ state }: ToastRegionProps) => (
  <RootPortal>
    <ToastRegionInner state={state} />
  </RootPortal>
)

const ToastRegionInner = ({ state }: ToastRegionProps) => {
  const ref = React.useRef(null)
  const { regionProps } = useToastRegion({}, state, ref)
  const { focusProps, isFocusVisible } = useFocusVisible()
  return (
    <StyledFlex
      flexDirection='column'
      alignItems='center'
      gap={3}
      ref={ref}
      position='fixed'
      top={7}
      left='50%'
      // zIndex needs to be larger than 100000 that is defined inside react-aria to be displayed over the modals
      zIndex={101000}
      mt={-2}
      p={2}
      boxShadow={isFocusVisible ? 'border/focus-indicator' : undefined}
      {...mergeProps(focusProps, regionProps)}
    >
      {state.visibleToasts.map(toast => (
        <Toast key={toast.key} toast={toast} state={state} />
      ))}
    </StyledFlex>
  )
}

const StyledFlex = styled(Flex)`
  transform: translateX(-50%);
  pointer-events: none;
  :focus {
    outline: none;
  }
`
