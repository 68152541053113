import React from 'react'
import { AriaMenuOptions, mergeProps, useMenu } from 'react-aria'
import { useTreeState, Item, TreeProps } from 'react-stately'
import { typeValidator } from '@chilipiper/utils'
import { Box } from '../../../core-components'
import { ActionItemInternal, ActionItemPublic } from './ActionItem'
import { ActionListBaseProvider } from './helpers'
import { CascadeItemInternal, CascadeItemPublic } from './CascadeItem'
import { useSubmenuContext } from './SubmenuContext'
import { useMenuTriggerState } from './useMenuTriggerState'

type Props = {
  ariaProps: { 'aria-label': string } | { 'aria-labelledby': string }
  children: React.ReactNode
  expandedItem?: string
  onAction?: (key: React.Key) => void
  onExpandedChange?: (item?: string) => void
  variant?: 'regular' | 'nested'
}

type ActionItemProps = React.ComponentProps<typeof ActionItemPublic>
type CascadeItemProps = React.ComponentProps<typeof CascadeItemPublic>
export const ActionList = ({
  ariaProps,
  children,
  expandedItem,
  onAction,
  onExpandedChange,
  variant = 'regular',
}: Props) => {
  const validatedChildren: Record<
    string,
    | React.ReactElement<ActionItemProps, React.FC<ActionItemProps>>
    | React.ReactElement<CascadeItemProps, React.FC<CascadeItemProps>>
  > = Object.fromEntries(
    React.Children.toArray(children).map(child => {
      if (typeValidator(ActionItemPublic)(child)) {
        return [child.props.children, child]
      }
      if (typeValidator(CascadeItemPublic)(child)) {
        return [child.props.title, child]
      }
      throw new Error('All Action.List children must be Action.Item or Action.CascadeItem')
    })
  )
  const validatedChildrenArray = Object.values(validatedChildren)

  const submenuProps = useSubmenuContext()
  const reactAriaProps: TreeProps<ActionItemProps | CascadeItemProps> &
    AriaMenuOptions<ActionItemProps | CascadeItemProps> = {
    ...mergeProps(ariaProps, submenuProps),
    children: dynamicItemToItem,
    items: validatedChildrenArray.map(child => child.props),
    disabledKeys: validatedChildrenArray
      .filter(item => item.props.isDisabled)
      .map(item => getKey(item.props)),
  }
  const state = useTreeState(reactAriaProps)
  const ref = React.useRef<HTMLUListElement>(null)
  const { menuProps } = useMenu(reactAriaProps, state, ref)
  const triggerState = useMenuTriggerState({
    isOpen: true,
    onExpandedChange,
    expandedItem,
  })
  const isNested = submenuProps?.submenuLevel !== undefined ? true : variant === 'nested'

  return (
    <Box {...(isNested ? {} : { boxShadow: 'border/popover', borderRadius: 8 })}>
      <Box
        as='ul'
        {...(isNested ? {} : { borderRadius: 8, bg: 'bg/dropdown' })}
        overflow='hidden'
        bg='bg/dropdown'
        minWidth={20}
        ref={ref}
        {...menuProps}
      >
        <ActionListBaseProvider value={{ listRef: ref, menuTriggerState: triggerState }}>
          {[...state.collection].map(item => {
            const validatedChildProps = validatedChildren[item.key].props
            if ('title' in validatedChildProps) {
              return (
                <CascadeItemInternal
                  key={item.key}
                  item={item}
                  state={state}
                  {...validatedChildProps}
                />
              )
            }
            return (
              <ActionItemInternal
                key={item.key}
                item={item}
                state={state}
                {...validatedChildProps}
                onAction={(key: React.Key) => {
                  onAction?.(key)
                  validatedChildProps.onAction?.()
                }}
              />
            )
          })}
        </ActionListBaseProvider>
      </Box>
    </Box>
  )
}

/**
 * Maps ActionItemPublic props to react-aria Item (to allow for search and other react-aria
 * behaviors)
 */
export const dynamicItemToItem = (
  itemData:
    | React.ComponentProps<typeof ActionItemPublic>
    | React.ComponentProps<typeof CascadeItemPublic>
) => {
  const key = getKey(itemData)
  return (
    <Item key={key} hasChildItems>
      {key}
    </Item>
  )
}
const getKey = (props: ActionItemProps | CascadeItemProps) => {
  if ('title' in props) {
    return props.title
  }
  if (typeof props.children === 'string') {
    return props.children
  }
  return props.children.join('')
}
