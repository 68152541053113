/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgIconCaution = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm10.5.75v-6h3v6h-3ZM12 15a1.5 1.5 0 0 1 1.5 1.5v.15a1.5 1.5 0 0 1-3 0v-.15A1.5 1.5 0 0 1 12 15Z'
      clipRule='evenodd'
    />
  </svg>
)
export default SvgIconCaution
