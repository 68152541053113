import React, { ComponentType } from 'react'
import { CustomAlertProps } from '../alert/Alert'
import { SuccessAlert, WarningAlert, ErrorAlert } from '../alert'
import { SnackbarBasicVariants, SnackbarShow } from './types'

const alertComponentMap: Record<SnackbarBasicVariants, ComponentType<CustomAlertProps>> = {
  warning: WarningAlert,
  error: ErrorAlert,
  success: SuccessAlert,
}

export const getSnackbarChildren = (props: SnackbarShow) => {
  const isCustom = getIsSnackbarCustom(props)
  if (isCustom) {
    return props.children
  }
  const { type = 'success', ...rest } = props
  const Snack = alertComponentMap[type]
  return <Snack title={rest.title} message={rest.message} />
}

const getIsSnackbarCustom = (
  snackbar: SnackbarShow
): snackbar is SnackbarShow & { type: 'custom' } => snackbar.type === 'custom'
