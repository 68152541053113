export const settings = {
  durations: {
    'fast-1': '70ms',
    'fast-2': '110ms',
    'moderate-1': '150ms',
    'moderate-2': '250ms',
    'slow-1': '400ms',
    'slow-2': '600ms',
  },
  easings: {
    standard: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
  },
}
