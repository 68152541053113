/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgCheckMark = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='m6.5 9.586 5.293-5.293 1.414 1.414L6.5 12.414 2.793 8.707l1.414-1.414L6.5 9.586Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgCheckMark
