import styledComponents, {
  createGlobalStyle as styledCreateGlobalStyle,
  css as styledCSS,
  keyframes as styledKeyframes,
  useTheme as styledUseTheme,
  ThemedStyledInterface,
  ServerStyleSheet as StyledServerStyleSheet,
  StyleSheetManager as StyledStyleSheetManager,
} from 'styled-components'
import { Theme } from './new/theme'

export const styled: ThemedStyledInterface<Theme> = styledComponents

export const createGlobalStyle = styledCreateGlobalStyle

export const css = styledCSS
export const ServerStyleSheet = StyledServerStyleSheet

export const StyleSheetManager = StyledStyleSheetManager

export const keyframes = styledKeyframes

export const useTheme: () => Theme = styledUseTheme
