import { palette } from './palette'

export const darkContextualBgPalette = {
  'bg/action-bar': palette['neutral-light-8'],
  'bg/button-major': palette['purple-dark-100'],
  'bg/button-hovered-major': palette['purple-dark-64'],
  'bg/button-moderate': palette['neutral-light-12'],
  'bg/button-hovered-moderate': palette['neutral-light-20'],
  'bg/button-focused-moderate': palette['neutral-light-8'],
  'bg/button-minor': palette['neutral-dark-100'],
  'bg/button-hovered-minor': palette['neutral-light-20'],
  'bg/button-caution': palette['red-light-100'],
  'bg/button-hovered-caution': palette['red-light-64'],
  'bg/action-bar-button-major': palette['purple-dark-100'],
  'bg/action-bar-button-hovered-major': palette['purple-dark-20'],
  'bg/action-bar-button-moderate': palette['neutral-light-12'],
  'bg/action-bar-button-hovered-moderate': palette['neutral-light-20'],
  'bg/action-bar-button-focused-moderate': palette['neutral-light-8'],
  'bg/action-bar-button-minor': palette['neutral-dark-100'],
  'bg/action-bar-button-hovered-minor': palette['neutral-light-20'],
  'bg/action-bar-button-caution': palette['red-light-100'],
  'bg/action-bar-button-hovered-caution': palette['red-light-12'],
  'bg/admin-center-layout': 'linear-gradient(264.43deg, #4d4b8e 0%, #1c1b31 99.99%, #1b1a2e 100%)',
  'bg/background-navigation': 'hsl(242, 29%, 17%, 1)',
  'bg/cell': palette['neutral-light-8'],
  'bg/container': `linear-gradient(0deg, ${palette['neutral-light-2']}, ${palette['neutral-light-2']}), ${palette['neutral-dark-100']}`,
  'bg/context-switcher': 'hsl(244, 29%, 19%, 1)',
  'bg/drop-zone': palette['blue-light-100'],
  'bg/list-item-caution-hover': palette['red-light-12'],
  'bg/list-item-hover': palette['neutral-light-8'],
  'bg/list-item-selected': palette['neutral-light-12'],
  'bg/list-item-unselected': 'transparent',
  'bg/disabled': palette['neutral-light-16'],
  'bg/chat-disabled': palette['white-32'],
  'bg/chat-reply-button': palette['white-8'],
  'bg/chat-reply-button-focused': palette['white-24'],
  'bg/chat-scrollbar-control': palette['white-16'],
  'bg/scrollbar-background': palette['neutral-light-4'],
  'bg/scrollbar-control': palette['neutral-light-12'],
  'bg/chat-form-item-error-focused': palette['red-dark-24'],
  'bg/chat-transparent': `linear-gradient(0deg, ${palette['neutral-dark-100']} 0%, transparent 43%)`,
  'bg/chat-form-item-unfocused': palette['white-12'],
  'bg/chat-block': `linear-gradient(0deg, ${palette['neutral-light-4']}, ${palette['neutral-light-4']}), ${palette['neutral-dark-100']}`,
  'bg/chat-bubble': `linear-gradient(0deg, ${palette['neutral-light-8']}, ${palette['neutral-light-8']}), ${palette['neutral-dark-100']}`,
  'bg/fire-modal-gradient':
    'linear-gradient(266.9deg, #7A365F 1.85%, #662F50 43.79%, #4D3A71 98.64%)',
  'bg/main-nav-item-hovered': palette['white-12'],
  'bg/main-nav-item-selected': palette['purple-light-20'],
  'bg/form-item-hover': palette['neutral-light-12'],
  'bg/form-item-error': palette['red-light-8'],
  'bg/form-item-unfocused': palette['neutral-dark-100'],
  'bg/moderate': `linear-gradient(0deg, ${palette['neutral-light-4']}, ${palette['neutral-light-4']}), ${palette['neutral-dark-100']}`,
  'bg/major': `linear-gradient(0deg, ${palette['neutral-light-8']}, ${palette['neutral-light-8']}), ${palette['neutral-dark-100']}`,
  'bg/page': palette['neutral-dark-100'],
  'bg/row': palette['neutral-light-8'],
  'bg/spinner-loader': palette['neutral-light-100'],
  'bg/switch-off': palette['neutral-light-40'],
  'bg/switch-on-disabled': palette['purple-dark-48'],
  'bg/switch-on-disabled-status': palette['green-dark-48'],
  'bg/switch-on': palette['purple-dark-100'],
  'bg/switch-on-status': palette['green-dark-100'],
  'bg/container-highlight': palette['neutral-light-8'],
  'bg/dropdown': `linear-gradient(0deg, ${palette['neutral-light-8']}, ${palette['neutral-light-8']}), ${palette['neutral-dark-100']}`,
  'bg/select-item-disabled-major': palette['purple-dark-48'],
  'bg/toast': `linear-gradient(0deg, ${palette['neutral-light-16']}, ${palette['neutral-light-16']}), ${palette['neutral-dark-100']}`,
  'bg/asset-pill': `linear-gradient(0deg, ${palette['neutral-light-8']}, ${palette['neutral-light-8']}), ${palette['neutral-dark-100']}`,
  'bg/asset-pill-hover': palette['neutral-light-20'],
  'bg/skeleton-background': palette['neutral-light-8'],
  'bg/skeleton-flicker': palette['neutral-light-20'],
  'bg/feedback-neutral': palette['neutral-light-12'],
  'bg/feedback-warning': palette['yellow-light-12'],
  'bg/feedback-caution': palette['red-light-12'],
  'bg/feedback-inform': palette['blue-light-12'],
  'bg/feedback-success': palette['green-light-12'],
}
