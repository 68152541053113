import { EndContent } from '../../data/end-content/EndContent'
import { ActionList } from './ActionList'
import { ActionItemPublic } from './ActionItem'
import { CascadeItemPublic } from './CascadeItem'

export const Action = {
  List: ActionList,
  Item: ActionItemPublic,
  CascadeItem: CascadeItemPublic,
  EndContent: EndContent,
}
