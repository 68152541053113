import { palette } from './palette'

export const contextualIconPalette = {
  'icon/action-caution': palette['red-dark-100'],
  'icon/action-major': palette['purple-dark-100'],
  'icon/action-minor': palette['neutral-dark-64'],
  'icon/action-moderate': palette['neutral-dark-100'],
  'icon/active': palette['green-dark-100'],
  'icon/bullet': palette['neutral-dark-20'],
  'icon/cp-logo': palette['orange-dark-100'],
  'icon/disabled': palette['neutral-dark-48'],
  'icon/draft': palette['yellow-dark-100'],
  'icon/error': palette['red-dark-100'],
  'icon/inactive': palette['neutral-dark-20'],
  'icon/indicator-incomplete': palette['neutral-dark-20'],
  'icon/indicator-selected': palette['neutral-dark-100'],
  'icon/indicator-unselected': palette['neutral-dark-32'],
  'icon/indicator-action': palette['yellow-dark-100'],
  'icon/indicator': palette['neutral-dark-32'],
  'icon/inform': palette['blue-dark-100'],
  'icon/item-selected-oncolor': palette['white-100'],
  'icon/item-selected': palette['neutral-dark-100'],
  'icon/item-unselected': palette['neutral-dark-80'],
  'icon/on-color': palette['white-100'],
  'icon/on-color-detail': palette['neutral-light-64'],
  'icon/success': palette['green-dark-100'],
  'icon/warning': palette['yellow-dark-100'],
  'icon/chat-item-selected': palette['neutral-dark-64'],
  'icon/chat-item-unselected': palette['neutral-dark-80'],
}
