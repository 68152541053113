import { Hotkey } from '@chilipiper/utils'
import { isMac } from '../../helpers'

export const getHotkeyString = (hotkey: Hotkey) => {
  let output = ''
  if (
    hotkey.modifier === 'metaKey' ||
    (Array.isArray(hotkey.modifier) && hotkey.modifier[0] === 'metaKey')
  ) {
    if (isMac()) {
      output += '⌘'
    } else {
      output += 'Ctrl + '
    }
  }
  if (
    hotkey.modifier === 'ctrlKey' ||
    (Array.isArray(hotkey.modifier) && hotkey.modifier[0] === 'ctrlKey')
  ) {
    if (isMac()) {
      output += '⌃'
    } else {
      output += 'Ctrl + '
    }
  }
  if (hotkey.modifier === 'shiftKey' || Array.isArray(hotkey.modifier)) {
    if (isMac()) {
      output += '⇧'
    } else {
      output += 'Shift + '
    }
  }
  return output + hotkey.key.toUpperCase()
}
