import React from 'react'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { AriaProps, OmitInternalProperties, PositioningProps } from '../../types'
import { Tooltip } from '../../feedback/tooltip/Tooltip'
import { AnchorOnlyProps, ButtonOnlyProps } from '../types'
import { useAnchorProps, useButtonProps } from '../hooks'
import {
  BaseButtonBox,
  BaseButtonIcon,
  baseButtonProps,
  BaseButtonVariant,
  baseButtonVariantProps,
} from '../base-button/BaseButton'

type CommonProps = {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
  ariaProps?: AriaProps
  'data-id'?: string
  'data-test-id'?: string
  hasTooltip?: boolean
  icon: React.ComponentType
  isDisabled?: boolean
  label: string
  size: 5 | 6 | 7 | 8
  variant: 'major' | 'moderate' | 'minor' | 'ghost'
} & PositioningProps

export type ButtonProps = CommonProps & ButtonOnlyProps
export type AnchorProps = CommonProps & AnchorOnlyProps

type Props = ButtonProps | AnchorProps
export const IconButtonInternal = ({ hasTooltip = true, ...props }: Props) => {
  if (props.as === 'a') {
    if (!hasTooltip) {
      return <IconButtonAsAnchor {...props} />
    }
    return (
      <Tooltip content={props.label}>
        <IconButtonAsAnchor {...props} />
      </Tooltip>
    )
  }
  if (!hasTooltip) {
    return <IconButtonAsButton {...props} />
  }
  return (
    <Tooltip content={props.label}>
      <IconButtonAsButton {...props} />
    </Tooltip>
  )
}

type PublicProps = OmitInternalProperties<ButtonProps> | OmitInternalProperties<AnchorProps>

export const IconButtonPublic = (props: PublicProps) => <IconButtonInternal {...props} />

const IconButtonAsButton = ({
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  ariaProps,
  onClick,
  size,
  label,
  icon,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  type,
  'data-id': dataId,
  'data-test-id': dataTestId,
  variant,
  isDisabled,
  ...positioningProps
}: ButtonProps) => {
  validatePositioningProps(positioningProps, 'IconButton')

  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    'aria-label': label,
    isDisabled,
    ...ariaProps,
  })

  const commonProps = getCommonProps(variant, isFocusVisible, size)

  return (
    <BaseButtonBox
      as='button'
      ref={ref}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...buttonProps}
    >
      <Icon icon={icon} size={size} />
    </BaseButtonBox>
  )
}

const IconButtonAsAnchor = ({
  INTERNAL_isFocusVisible,
  onClick,
  size,
  label,
  icon,
  'data-id': dataId,
  'data-test-id': dataTestId,
  variant,
  ariaProps,
  INTERNAL_nativeOnClick,
  as: _as, // eslint-disable-line @typescript-eslint/no-unused-vars
  rel,
  href,
  target,
  isDisabled,
  ...positioningProps
}: AnchorProps) => {
  validatePositioningProps(positioningProps, 'IconButton')

  const { anchorProps, isFocusVisible, ref } = useAnchorProps({
    ...ariaProps,
    onClick,
    INTERNAL_isFocusVisible,
    INTERNAL_nativeOnClick,
    'aria-label': label,
    isDisabled,
    href,
    target,
    rel,
  })
  const commonProps = getCommonProps(variant, isFocusVisible, size)

  return (
    <BaseButtonBox
      as='a'
      ref={ref}
      data-id={dataId}
      data-test-id={dataTestId}
      {...commonProps}
      {...positioningProps}
      {...anchorProps}
    >
      <Icon icon={icon} size={size} />
    </BaseButtonBox>
  )
}

const buttonVariantProps: Record<
  Props['variant'],
  Omit<(typeof baseButtonVariantProps)[BaseButtonVariant], 'color' | 'hoverColor'>
> = {
  major: baseButtonVariantProps.major,
  moderate: {
    bg: 'bg/button-moderate',
    hoverBg: 'bg/button-hovered-moderate',
    iconColor: 'icon/action-minor',
    iconHoverColor: 'icon/action-minor',
  },
  minor: baseButtonVariantProps.minor,
  ghost: {
    bg: 'transparent',
    hoverBg: 'bg/button-hovered-minor',
    iconColor: 'icon/action-minor',
    iconHoverColor: 'icon/action-minor',
  },
}

const getCommonProps = (
  variant: Props['variant'],
  isFocusVisible: boolean,
  size: Props['size']
) => {
  const { bg, border, hoverBg, iconColor, iconHoverColor } = buttonVariantProps[variant]
  const borderRadius = size <= 6 ? 8 : 10

  return {
    ...baseButtonProps,
    borderRadius,
    px: 0,
    py: 0,
    w: size,
    h: size,
    bg,
    boxShadow: border,
    hoverBg,
    iconColor,
    iconHoverColor,
    iconFocusColor: isFocusVisible ? iconHoverColor : undefined,
    focusBg: isFocusVisible ? hoverBg : undefined,
    focusShadow: isFocusVisible ? 'border/focus-indicator' : undefined,
    flexShrink: 0,
  } as const
}
const Icon = ({ size, icon }: Pick<Props, 'size' | 'icon'>) => {
  const iconSize = getIconSize(size)
  return <BaseButtonIcon icon={icon} w={iconSize} h={iconSize} />
}

const getIconSize = (size: Props['size']) => {
  if (size === 8) {
    return 5
  }
  if (size === 5) {
    return 3
  }
  return 4
}
