/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgHiddenOptions = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16' {...props}>
    <path
      fill='currentColor'
      d='M4.172 7.766c0 .8649-.701 1.566-1.566 1.566-.8648 0-1.566-.7011-1.566-1.566 0-.8649.7012-1.566 1.566-1.566.865 0 1.566.7011 1.566 1.566Zm5.568 0c0 .8649-.701 1.566-1.566 1.566-.8648 0-1.566-.7011-1.566-1.566 0-.8649.7012-1.566 1.566-1.566.865 0 1.566.7011 1.566 1.566Zm5.22 0c0 .8649-.7011 1.566-1.566 1.566-.8648 0-1.566-.7011-1.566-1.566 0-.8649.7012-1.566 1.566-1.566.8649 0 1.566.7011 1.566 1.566Z'
    />
  </svg>
)
export default SvgHiddenOptions
