import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Text } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { PositioningProps } from '../../types'
import { Tooltip } from '../tooltip/Tooltip'
import { ThemeColors } from '../../../theme'

type Variant = 'neutral' | 'warning' | 'inform' | 'success' | 'caution'
type Props = {
  'data-test-id'?: string
  label: string
  size?: 'default' | 'compact'
  variant: Variant
} & PositioningProps

export const Badge = ({
  'data-test-id': testId,
  label,
  variant,
  size = 'default',
  ...positioningProps
}: Props) => {
  validatePositioningProps(positioningProps, 'Badge')

  if (size === 'compact') {
    return (
      <Tooltip content={label}>
        <Tooltip.NonFocusableTrigger>
          <StyledCompactBadge
            {...positioningProps}
            data-test-id={testId}
            variant={variant}
            alignItems='center'
            justifyContent='center'
            borderRadius={6}
            w={4}
            h={4}
          >
            <StyledCompactBadgeCore w='6px' h='6px' borderRadius={6} />
          </StyledCompactBadge>
        </Tooltip.NonFocusableTrigger>
      </Tooltip>
    )
  }

  return (
    <StyledBadge
      {...positioningProps}
      as='div'
      data-test-id={testId}
      py='3px'
      px={2}
      textStyle='heading-2xs'
      color={textColors[variant]}
      variant={variant}
    >
      {label}
    </StyledBadge>
  )
}

const textColors = {
  neutral: 'text/detail-moderate',
  warning: 'text/action-warning',
  caution: 'text/action-caution',
  inform: 'text/action-inform',
  success: 'text/form-success',
} satisfies Record<Variant, ThemeColors>

const bgColors = {
  neutral: 'bg/feedback-neutral',
  warning: 'bg/feedback-warning',
  caution: 'bg/feedback-caution',
  inform: 'bg/feedback-inform',
  success: 'bg/feedback-success',
} satisfies Record<Variant, ThemeColors>

const bgCoreColors = {
  neutral: 'icon/indicator',
  warning: 'icon/warning',
  caution: 'icon/action-caution',
  inform: 'icon/inform',
  success: 'icon/success',
} satisfies Record<Variant, ThemeColors>

const StyledBadge = styled(Text)<{ variant: Variant }>`
  border-radius: 24px;
  background: ${({ theme, variant }) => theme.colors[bgColors[variant]]};
  white-space: nowrap;
`

const StyledCompactBadgeCore = styled(Box)``
const StyledCompactBadge = styled(Flex)<{ variant: Variant }>`
  background: ${({ theme, variant }) => theme.colors[bgColors[variant]]};
  ${StyledCompactBadgeCore} {
    background: ${({ theme, variant }) => theme.colors[bgCoreColors[variant]]};
  }

  :hover {
    background: ${({ theme, variant }) => theme.colors[bgCoreColors[variant]]};
    ${StyledCompactBadgeCore} {
      background: ${({ theme }) => theme.colors['icon/on-color']};
    }
  }
`
