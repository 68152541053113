import { palette } from './palette'

export const darkContextualIconPalette = {
  'icon/action-caution': palette['red-light-100'],
  'icon/action-minor': palette['neutral-light-80'],
  'icon/action-major': palette['purple-light-100'],
  'icon/action-moderate': palette['white-100'],
  'icon/bullet': palette['white-20'],
  'icon/item-unselected': palette['neutral-light-100'],
  'icon/item-selected': palette['white-100'],
  'icon/on-color': palette['white-100'],
  'icon/error': palette['red-light-100'],
  'icon/success': palette['green-light-100'],
  'icon/indicator': palette['white-48'],
  'icon/disabled': palette['neutral-light-56'],
  'icon/indicator-selected': palette['white-100'],
  'icon/chat-item-selected': palette['white-100'],
  'icon/chat-item-unselected': palette['white-80'],
}
