import { remToPx } from './remToPx'

const fontWeights = {
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
}
const fonts = {
  primary: 'Inter, "Inter fallback", sans-serif',
  monospace: 'IBM Plex Mono, "IBM Plex Mono fallback", monospace',
}

const fontSizes = {
  10: '0.625rem',
  12: '.75rem',
  14: '.875rem',
  16: '1rem',
  18: '1.125rem',
  20: '1.25rem',
  24: '1.5rem',
  32: '2rem',
  36: '2.25rem',
  40: '2.5rem',
}
const lineHeights = {
  none: '100%',
  2: 'calc(100% + 0.125rem)',
  4: 'calc(100% + 0.25rem)',
  8: 'calc(100% + 0.5rem)',
}

export const getTypography = (unit?: 'px') => {
  if (unit === 'px') {
    return {
      fontWeights,
      fonts,
      fontSizes: Object.fromEntries(
        Object.entries(fontSizes).map(([key, value]) => [key, remToPx(value)])
      ) as typeof fontSizes,
      lineHeights: Object.fromEntries(
        Object.entries(lineHeights).map(([key, value]) => [key, remToPx(value)])
      ) as typeof lineHeights,
    }
  }
  return {
    fontWeights,
    fonts,
    fontSizes,
    lineHeights,
  }
}
