import React, { forwardRef, useRef } from 'react'
import styled, { css } from 'styled-components'
import {
  AriaPositionProps,
  DismissButton,
  mergeProps,
  OverlayTriggerProps,
  useDialog,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from 'react-aria'
import { useObjectRef } from '@react-aria/utils'
import { useOverlayTriggerState } from 'react-stately'
import { AnimatePresence, motion, useIsPresent } from 'framer-motion'
import { AriaProps } from '../../components/types'
import { useFocusVisible } from '../../components/hooks'
import { useElementScrolledOut } from '../../internal-helpers/useElementScrolledOut'
import { SafeFocusScope } from '../../internal-helpers/SafeFocusScope'
import { useAriaHideOutside } from '../../helpers/useAriaHideOutside'
import { RootPortal } from '../portal/RootPortal'

interface Props {
  ariaProps?: { type: OverlayTriggerProps['type'] }
  children: React.ReactNode
  crossOffset?: number
  isOpen?: boolean
  offset?: number
  onOpenChange?: (isOpen: boolean) => void
  placement?: AriaPositionProps['placement']
  trigger: (triggerProps: AriaProps) => React.ReactNode
}

export const Popover = ({
  ariaProps,
  trigger,
  children,
  isOpen,
  onOpenChange,
  placement,
  offset,
  crossOffset,
}: Props) => {
  const triggerRef = useRef<HTMLButtonElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)
  const state = useOverlayTriggerState({ isOpen, onOpenChange })
  const { overlayProps: overlayPositionProps, updatePosition } = useOverlayPosition({
    overlayRef: popoverRef,
    targetRef: triggerRef,
    placement: placement,
    isOpen: state.isOpen,
    offset,
    crossOffset,
  })
  const { triggerProps, overlayProps } = useOverlayTrigger(
    ariaProps ?? { type: 'dialog' },
    {
      ...state,
      close: updatePosition, // by default overlay closes on scroll https://github.com/adobe/react-spectrum/issues/1852#issuecomment-873560234
    },
    triggerRef
  )

  return (
    <>
      {trigger({ ...triggerProps, elementRef: triggerRef })}
      <RootPortal>
        <AnimatePresence>
          {state.isOpen && (
            <MotionPopoverOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              overlayProps={mergeProps(overlayProps, overlayPositionProps)}
              onClose={state.close}
              ref={popoverRef}
              triggerRef={triggerRef}
            >
              {children}
            </MotionPopoverOverlay>
          )}
        </AnimatePresence>
      </RootPortal>
    </>
  )
}

type PopoverContentProps = {
  children: React.ReactNode
  onClose: () => void
  overlayProps: React.DOMAttributes<HTMLDivElement>
  triggerRef: React.RefObject<HTMLButtonElement>
}

const PopoverOverlay = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ onClose, children, triggerRef, overlayProps: otherProps }, ref) => {
    const popoverRef = useObjectRef(ref)
    const { overlayProps } = useOverlay(
      {
        isOpen: true,
        onClose,
        isDismissable: true,
      },
      popoverRef
    )
    const { dialogProps } = useDialog({}, popoverRef)
    const { focusProps, isFocusVisible } = useFocusVisible()

    useElementScrolledOut(triggerRef, onClose)

    useAriaHideOutside([popoverRef])

    return (
      <PresenceSafeFocusScope restoreFocus contain>
        <StyledWrapper
          isFocusVisible={isFocusVisible}
          ref={popoverRef}
          {...mergeProps(dialogProps, otherProps, overlayProps, focusProps)}
        >
          {children}
        </StyledWrapper>
        <DismissButton onDismiss={onClose} />
      </PresenceSafeFocusScope>
    )
  }
)
const MotionPopoverOverlay = motion(PopoverOverlay)

const PresenceSafeFocusScope = (props: React.ComponentProps<typeof SafeFocusScope>) => {
  const isPresent = useIsPresent()
  return isPresent ? <SafeFocusScope {...props} /> : <>{props.children}</>
}

const StyledWrapper = styled.div<{ isFocusVisible: boolean }>`
  ${({ isFocusVisible }) =>
    !isFocusVisible &&
    css`
      :focus {
        outline: none;
      }
    `}
`
