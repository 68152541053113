import React from 'react'
import { SvgNewTab } from '@chilipiper/icons'
import { BaseActionBarButton } from '../BaseActionBarButton'
import { useAnchorProps } from '../../hooks'

export type ActionBarNewTabButtonPublicProps = {
  children: string
  'data-id'?: string
  'data-test-id'?: string
  href: string
  isDisabled?: boolean
}

type InternalProps = {
  INTERNAL_hasNextSibling?: boolean
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_isOnlyChild?: boolean
}

type Props = InternalProps & ActionBarNewTabButtonPublicProps

export const ActionBarNewTabButton = ({
  isDisabled,
  children,
  'data-id': dataId,
  INTERNAL_isFocusVisible,
  'data-test-id': dataTestId,
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  href,
}: Props) => {
  const { ref, isFocusVisible, anchorProps } = useAnchorProps({
    INTERNAL_isFocusVisible,
    isDisabled,
    href,
    target: '_blank',
    rel: 'noopener noreferrer',
  })

  return (
    <BaseActionBarButton
      variant='minor'
      wrapper={{ as: 'a', ref }}
      endIcon={SvgNewTab}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
      INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
      {...anchorProps}
    >
      {children}
    </BaseActionBarButton>
  )
}
ActionBarNewTabButton.dsId = 'action-bar-new-tab-button'
