import React from 'react'
import type { AriaProps } from '../../../types'
import { useButtonProps } from '../../hooks'
import type { BaseActionBarButtonVariant } from '../BaseActionBarButton'
import { BaseActionBarButton } from '../BaseActionBarButton'

export type ActionBarButtonPublicProps = {
  ariaProps?: AriaProps
  children: string | string[]
  'data-id'?: string
  'data-test-id'?: string
  isDisabled?: boolean
  isLoading?: boolean
  onClick: () => void
  startIcon?: React.ComponentType
  tooltip?: string
  variant: BaseActionBarButtonVariant
}

type InternalProps = {
  INTERNAL_hasNextSibling?: boolean
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_isOnlyChild?: boolean
}

type Props = InternalProps & ActionBarButtonPublicProps

export const ActionBarButton = ({
  isDisabled,
  variant,
  children,
  'data-id': dataId,
  ariaProps,
  INTERNAL_isFocusVisible,
  'data-test-id': dataTestId,
  onClick,
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  startIcon,
  isLoading,
  tooltip,
}: Props) => {
  const { ref, isFocusVisible, buttonProps } = useButtonProps({
    onClick,
    type: 'button',
    INTERNAL_isFocusVisible,
    isDisabled: isDisabled || isLoading,
    ...ariaProps,
  })

  return (
    <BaseActionBarButton
      variant={variant}
      wrapper={{ as: 'button', ref }}
      startIcon={startIcon}
      isLoading={isLoading}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
      INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
      tooltip={tooltip}
      {...buttonProps}
    >
      {children}
    </BaseActionBarButton>
  )
}
ActionBarButton.dsId = 'action-bar-button'
