import { nanoid } from 'nanoid'
import React, { ReactNode, useCallback, useState } from 'react'
import { createContextWithHook } from '@chilipiper/utils'
import { ModalWithProvider } from './ModalWithFlowProvider'
import { AddModalFunction, ModalContext, ModalData } from './types'

const initialData: Array<ModalData> = []

const { ModalBaseProvider, ...ModalContextRest } = createContextWithHook<ModalContext>()('Modal')
export const { useModalContext } = ModalContextRest

/**
 * @deprecated - Use ConfirmationModal, ConfigurationModal or Modal from
 *   `@chilipiper/design-system/src/new` instead
 */
export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setModals] = useState(initialData)

  const addModal = useCallback<AddModalFunction>(
    newModalData => {
      const key = nanoid()
      const setPromiseMethods = () => {
        const promise = new Promise((resolve, reject) => {
          setModals(modals =>
            modals.map(modal => (modal.key === key ? { ...modal, reject, resolve } : modal))
          )
        })
        promise.finally(setPromiseMethods)
        return promise
      }

      setModals(data => [...data, { ...newModalData, key }])
      const modalPromise = setPromiseMethods()
      return modalPromise
    },
    [setModals]
  )

  const removeModal = useCallback(
    (modalKey: string) => setModals(data => data.filter(({ key }) => key !== modalKey)),
    [setModals]
  )

  return (
    <ModalBaseProvider value={{ addModal }}>
      {children}
      {modals.map(({ key, ...modalData }) => (
        <ModalWithProvider
          key={key}
          onClose={() => {
            removeModal(key)
            if ('onCancel' in modalData) {
              modalData.onCancel?.()
            }
          }}
          {...modalData}
        />
      ))}
    </ModalBaseProvider>
  )
}
