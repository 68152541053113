import React, { ComponentType } from 'react'
import invariant from 'tiny-invariant'
import { typeValidator } from '@chilipiper/utils'
import { Flex } from '../../../core-components'
import { validatePositioningProps } from '../../../helpers/validatePositioningProps'
import { PositioningProps } from '../../types'
import { ActionBarButton, ActionBarButtonPublicProps } from './action-bar-button/ActionBarButton'
import { ActionBarUsageCheck } from './ActionBarContext'
import {
  ActionBarAddButton,
  ActionBarAddButtonProps,
} from './action-bar-add-button/ActionBarAddButton'
import {
  ActionBarNewTabButton,
  ActionBarNewTabButtonPublicProps,
} from './action-bar-new-tab-button/ActionBarNewTabButton'
import {
  ActionBarOptionButton,
  ActionBarOptionButtonPublicProps,
} from './action-bar-option-button/ActionBarOptionButton'
import {
  ActionBarMoreButton,
  ActionBarMoreButtonPublicProps,
} from './action-bar-more-button/ActionBarMoreButton'
import {
  ActionBarIconButton,
  ActionBarIconButtonPublicProps,
} from './action-bar-icon-button/ActionBarIconButton'

type Props = {
  ariaGroupLabel?: string
  children: React.ReactNode
  variant: 'nested' | 'regular' | 'inRow'
} & PositioningProps

export const ActionBar = ({ children, variant, ariaGroupLabel, ...positioningProps }: Props) => {
  validatePositioningProps(positioningProps, 'ActionBar')

  const childrenArray = React.Children.toArray(children)
  const allChildrenActionBarButtons = childrenArray.every(
    typeValidator(
      ActionBarButton,
      ActionBarAddButton,
      ActionBarNewTabButton,
      ActionBarOptionButton,
      ActionBarMoreButton,
      ActionBarIconButton
    )
  )
  invariant(
    allChildrenActionBarButtons,
    `All children of ActionBar must be one of:
  ActionBar.Button
  ActionBar.AddButton
  ActionBar.NewTabButton
  ActionBar.OptionButton
  ActionBar.MoreButton
  ActionBar.IconButton`
  )

  return (
    <ActionBarUsageCheck>
      <Flex
        w={variant === 'inRow' ? 'fit-content' : '100%'}
        h={variant === 'inRow' ? '100%' : undefined}
        boxShadow={variant === 'regular' ? 'border/on-major' : undefined}
        borderRadius={variant === 'regular' ? 8 : undefined}
        overflow='hidden'
        flexShrink={0}
        bg={variant !== 'inRow' ? 'bg/action-bar' : undefined}
        role={ariaGroupLabel !== undefined ? 'group' : undefined}
        aria-label={ariaGroupLabel}
        {...positioningProps}
      >
        {childrenArray.map((child, index) => {
          return React.cloneElement(child, {
            INTERNAL_hasNextSibling: childrenArray.length > index + 1,
            INTERNAL_isOnlyChild: childrenArray.length === 1,
          })
        })}
      </Flex>
    </ActionBarUsageCheck>
  )
}

ActionBar.Button = ActionBarButton as ComponentType<ActionBarButtonPublicProps> & {
  readonly dsId: string
}
ActionBar.AddButton = ActionBarAddButton as ComponentType<
  ActionBarAddButtonProps<ActionBarButtonPublicProps>
> & {
  readonly dsId: string
}
ActionBar.NewTabButton =
  ActionBarNewTabButton as ComponentType<ActionBarNewTabButtonPublicProps> & {
    readonly dsId: string
  }
ActionBar.OptionButton =
  ActionBarOptionButton as ComponentType<ActionBarOptionButtonPublicProps> & {
    readonly dsId: string
  }
ActionBar.MoreButton = ActionBarMoreButton as ComponentType<ActionBarMoreButtonPublicProps> & {
  readonly dsId: string
}
ActionBar.IconButton = ActionBarIconButton as ComponentType<ActionBarIconButtonPublicProps>
