import { getTypography } from './typography'
import { getSpacing } from './spacing'
import { getBorderRadius } from './borders'
import { getColors } from './colors'
import { settings as breakpoints } from './breakpoints'
import { settings as alignments } from './alignments'
import { settings as animations } from './animations'

export const defaultTheme = {
  ...getColors(),
  ...getSpacing(),
  ...getTypography(),
  ...getBorderRadius(),
  ...breakpoints,
  ...alignments,
  ...animations,
} as const

export const defaultPxTheme = {
  ...getColors('px'),
  ...getSpacing('px'),
  ...getTypography('px'),
  ...getBorderRadius('px'),
  ...breakpoints,
  ...alignments,
  ...animations,
} as const
