import { remToPx } from './remToPx'

const radii = {
  none: '0',
  2: '0.125rem',
  4: '0.25rem',
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  16: '1rem',
  20: '1.25rem',
  24: '1.5rem',
  32: '2rem',
  full: '100%',
}

export const getBorderRadius = (unit?: 'px') => {
  if (unit === 'px') {
    return {
      radii: Object.fromEntries(
        Object.entries(radii).map(([key, value]) => [key, remToPx(value)])
      ) as typeof radii,
    }
  }
  return {
    radii,
  }
}
