import React, { forwardRef, HTMLAttributes, useEffect, useRef } from 'react'
import { useTooltipTriggerState } from 'react-stately'
import {
  AriaPositionProps,
  mergeProps,
  useOverlayPosition,
  useTooltip,
  useTooltipTrigger,
} from 'react-aria'
import { AnimatePresence, motion } from 'framer-motion'
import { FocusableProvider, useFocusable } from '@react-aria/focus'
import { useObjectRef } from '@react-aria/utils'
import { createContextWithHook } from '@chilipiper/utils'
import { Box, RootPortal } from '../../../core-components'
import {
  Tip,
  TipBody,
  TipCustomContent,
  TipList,
  TipMedia,
  TipText,
  TipTitle,
  validateTipChildren,
} from '../tip/Tip'

type Props = {
  children: React.ReactNode
  content: React.ReactNode
  crossOffset?: number
  'data-test-id'?: string
  delay?: number
  isDisabled?: boolean
  offset?: number
  placement?: AriaPositionProps['placement']
}

export const Tooltip = ({
  children,
  placement = 'top',
  isDisabled,
  'data-test-id': dataTestId,
  content,
  offset = 8,
  crossOffset,
  delay = 500,
}: Props) => {
  validateTipChildren(content, false, 'Tooltip')

  const ariaProps = { isDisabled, delay, closeDelay: 250 }
  const state = useTooltipTriggerState(ariaProps)
  const triggerRef = useRef<HTMLElement>(null)
  const tipRef = useRef<HTMLDivElement>(null)
  const { tooltipProps: tooltipTriggerProps, triggerProps } = useTooltipTrigger(
    ariaProps,
    state,
    triggerRef
  )
  const { tooltipProps } = useTooltip({}, state)
  const { overlayProps, updatePosition } = useOverlayPosition({
    overlayRef: tipRef,
    targetRef: triggerRef,
    placement,
    isOpen: state.isOpen,
    offset,
    crossOffset,
  })
  useEffect(() => {
    const handleScroll = () => {
      updatePosition()
    }
    window.addEventListener('scroll', handleScroll, true)
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [updatePosition])
  return (
    <FocusableProvider {...triggerProps} ref={triggerRef}>
      <TooltipTriggerBaseProvider value={null}>{children}</TooltipTriggerBaseProvider>
      <RootPortal>
        <AnimatePresence>
          {state.isOpen && (
            <MotionTip
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              containerAttributes={mergeProps(tooltipTriggerProps, tooltipProps, overlayProps)}
              ref={tipRef}
              data-test-id={dataTestId}
            >
              {content}
            </MotionTip>
          )}
        </AnimatePresence>
      </RootPortal>
    </FocusableProvider>
  )
}

const MotionTip = motion(
  forwardRef<HTMLDivElement, Omit<React.ComponentProps<typeof Tip>, 'tipRef'>>((props, ref) => {
    const tipRef = useObjectRef(ref)
    return <Tip {...props} tipRef={tipRef} />
  })
)

const NonFocusableTrigger = ({
  children,
  ...props
}: { children: React.ReactNode } & React.ComponentProps<typeof Box>) => {
  useTooltipTriggerContext() // Check if used inside Tooltip as trigger
  const ref = useRef(null)
  const { focusableProps } = useFocusable({ isDisabled: undefined }, ref)
  return (
    <Box {...focusableProps} ref={ref} {...props}>
      {children}
    </Box>
  )
}

const CustomFocusableTrigger = ({
  children,
}: {
  children: (
    triggerProps: Omit<HTMLAttributes<any>, 'color'> & { ref: React.RefObject<any> }
  ) => JSX.Element
}) => {
  useTooltipTriggerContext() // Check if used inside Tooltip as trigger
  const ref = useRef(null)
  const { focusableProps } = useFocusable({ isDisabled: undefined }, ref)
  return children(mergeProps({ ...focusableProps, ref }))
}

const { useTooltipTriggerContext, TooltipTriggerBaseProvider } =
  createContextWithHook<null>()('TooltipTrigger')

Tooltip.NonFocusableTrigger = NonFocusableTrigger
Tooltip.CustomFocusableTrigger = CustomFocusableTrigger
Tooltip.List = TipList
Tooltip.CustomContent = TipCustomContent
Tooltip.Media = TipMedia
Tooltip.Title = TipTitle
Tooltip.Text = TipText
Tooltip.Body = TipBody
