/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgSmartFeature = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' {...props}>
    <path
      fill='currentColor'
      d='M10.836 9.855c.685-.152 1.214-.642 1.362-1.26l1.75-7.308c.105-.436.8-.436.904 0l1.75 7.307c.148.62.677 1.109 1.362 1.261l5.685 1.263c.468.104.468.7 0 .804l-5.685 1.263c-.685.152-1.214.642-1.362 1.26l-1.75 7.308c-.105.436-.8.436-.904 0l-1.75-7.307c-.148-.62-.677-1.109-1.362-1.261L5.15 11.922c-.468-.104-.468-.7 0-.804l5.685-1.263ZM1.81 4.043c.206-.07.365-.291.41-.573L2.744.15c.032-.199.24-.199.272 0L3.54 3.47c.045.282.204.504.41.573l1.705.574c.14.048.14.319 0 .366l-1.706.574c-.205.07-.364.291-.409.573L3.016 9.45c-.032.199-.24.199-.272 0L2.22 6.13c-.045-.282-.204-.504-.41-.573L.106 4.983c-.14-.047-.14-.318 0-.366l1.706-.574ZM5.65 18.443c.206-.069.365-.291.41-.573l.524-3.321c.032-.199.24-.199.272 0l.524 3.321c.045.282.204.504.41.573l1.705.574c.14.047.14.319 0 .366l-1.706.574c-.205.07-.364.291-.409.573l-.524 3.321c-.032.199-.24.199-.272 0L6.06 20.53c-.045-.282-.204-.504-.41-.573l-1.705-.574c-.14-.047-.14-.319 0-.366l1.706-.574Z'
    />
  </svg>
)
export default SvgSmartFeature
