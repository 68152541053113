import React, { useRef } from 'react'
import { AriaButtonProps, mergeProps, useButton, useLink } from 'react-aria'
import { AriaProps } from '../types'
import { useFocusVisible } from '../hooks'
import { splitAriaProps } from '../helpers'

export const useButtonProps = ({
  isDisabled,
  type,
  onClick,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  ...ariaProps
}: {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
  isDisabled?: boolean
  onClick?: AriaButtonProps['onPress']
  type?: 'button' | 'submit' | 'reset'
} & AriaProps) => {
  const { elementRef, reactAriaProps, domProps } = splitAriaProps(ariaProps)
  const fallbackRef = useRef<HTMLButtonElement>(null)
  const ref = elementRef ?? fallbackRef
  const { buttonProps } = useButton(
    {
      elementType: 'button',
      type: type,
      onPress: onClick,
      isDisabled: isDisabled,
      ...reactAriaProps,
    },
    ref
  )
  const { focusProps, isFocusVisible } = useFocusVisible(INTERNAL_isFocusVisible)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color: _color, ...propsWithoutColor } = mergeProps(
    !isDisabled ? { onClick: INTERNAL_nativeOnClick } : {},
    buttonProps,
    focusProps
  )
  return { ref, buttonProps: mergeProps(propsWithoutColor, domProps), isFocusVisible }
}

export const useAnchorProps = ({
  isDisabled,
  onClick,
  INTERNAL_isFocusVisible,
  INTERNAL_nativeOnClick,
  ...ariaProps
}: {
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_nativeOnClick?: (event: React.MouseEvent) => void
  isDisabled?: boolean
  onClick?: AriaButtonProps['onPress']
} & AriaProps) => {
  const ref = useRef<HTMLAnchorElement>(null)
  const { linkProps } = useLink(
    {
      isDisabled: isDisabled,
      onPress: onClick,
      ...ariaProps,
      elementType: 'a',
    },
    ref
  )
  const { focusProps, isFocusVisible } = useFocusVisible(INTERNAL_isFocusVisible)
  return {
    ref,
    anchorProps: mergeProps(
      !isDisabled ? { onClick: INTERNAL_nativeOnClick } : {},
      linkProps,
      focusProps
    ),
    isFocusVisible,
  }
}
