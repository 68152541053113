import React, { useMemo } from 'react'
import { ModalFlowContextActions } from '../types'
import { ConfirmationModal } from '../confirmation-modal/ConfirmationModal'
import { ModalFlowBaseProvider } from './ModalFlowProvider'
import { ModalData } from './types'

export const ModalWithProvider = ({
  reject,
  resolve,
  onClose,
  ...rest
}: ModalData & ModalFlowContextActions) => {
  const commonProps = useMemo(
    () => ({
      isOpen: true,
      onClose,
    }),
    [onClose]
  )

  const child = useMemo(() => {
    if ('modalNode' in rest) {
      return rest.modalNode
    }
    return <ConfirmationModal onCancel={onClose} {...rest} {...commonProps} />
  }, [commonProps, rest, onClose])

  return (
    <ModalFlowBaseProvider
      value={{
        reject,
        resolve,
        ...commonProps,
      }}
    >
      {child}
    </ModalFlowBaseProvider>
  )
}
