import React from 'react'

import { Flex, Box } from '../../new/core-components/box'
import { Card, CardProps } from '../card'
import { Icon } from '../icon'
import { Text } from '../../new/core-components/text'
import { ThemeColors } from '../../new/theme'

type Props = {
  button?: React.ReactNode
  icon: React.ComponentType
  iconColor?: ThemeColors
  message?: string | React.ReactNode
  messageColor?: ThemeColors
  title?: string
  titleColor?: ThemeColors
} & CardProps

export type CustomAlertProps = Omit<Props, 'icon'>

export const Alert = ({
  title,
  message,
  icon,
  button,
  iconColor,
  titleColor = 'white',
  messageColor = 'text/body-major-oncolor',
  ...cardProps
}: Props) => {
  return (
    <Card bg='bg/toast' px={5} {...cardProps} p={0}>
      <Flex alignItems='center'>
        <Flex id='flex' alignItems='center' p={4} justifyContent='start' w='100%'>
          <Box>
            <Icon h={5} w={5} icon={icon} color={iconColor} />
          </Box>
          <Box ml={4} role='alert'>
            {title ? (
              <Box>
                <Text display='block' fontWeight={600} color={titleColor}>
                  {title}
                </Text>
              </Box>
            ) : null}
            {message ? (
              <Text fontWeight={500} color={messageColor}>
                {message}
              </Text>
            ) : null}
          </Box>
        </Flex>
        {button ? (
          <Flex h='100%' w='100%' justifyContent='center'>
            {button}
          </Flex>
        ) : null}
      </Flex>
    </Card>
  )
}
