import React, { ReactNode, useCallback, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { createContextWithHook } from '@chilipiper/utils'

const EXPERIMENTAL_FLAG_KEY = 'CP_EXPERIMENTAL_DARK_THEME'

type ProviderValue = {
  active: boolean
  toggle: () => void
}

const { useDarkModeContext, DarkModeBaseProvider } =
  createContextWithHook<ProviderValue>()('DarkMode')
export { useDarkModeContext }

interface Props {
  children: ReactNode
}

export const DarkModeProvider = ({ children }: Props) => {
  const [active, setActive] = useLocalStorage<string>(EXPERIMENTAL_FLAG_KEY, undefined)

  const toggle = useCallback(() => {
    setActive(active ? '' : 'true')
  }, [setActive, active])

  const value = useMemo(() => {
    return { active: Boolean(active), toggle }
  }, [active, toggle])

  return <DarkModeBaseProvider value={value}>{children}</DarkModeBaseProvider>
}
