import { ComponentProps } from 'react'
import styled from 'styled-components'
import { Box } from '../box'

export const Divider = styled(Box).attrs(
  props => ({ bg: 'bg/row', height: '1px', ...props } as ComponentProps<typeof Box>)
)``

export const VerticalDivider = styled(Box).attrs(
  props =>
    ({
      bg: 'bg/row',
      height: '100%',
      width: '1px',
      flexShrink: 0,
      ...props,
    } as ComponentProps<typeof Box>)
)``
