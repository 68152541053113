import { palette } from './palette'
import { contextualBgPalette } from './contextualBgPalette'
import { contextualTextPalette } from './contextualTextPalette'
import { contextualIconPalette } from './contextualIconPalette'
import { palette as borderPalette } from './borderPalette'
import { contextualBorderPalette } from './contextualBorderPalette'
import { remToPx } from './remToPx'

const shadows = {
  none: 'none',
  ...borderPalette,
  ...contextualBorderPalette,
}
export const getColors = (unit?: 'px') => ({
  opacities: {
    '0': 0,
    '2': 0.02,
    '3': 0.03,
    '4': 0.04,
    '8': 0.08,
    '12': 0.12,
    '16': 0.16,
    '20': 0.2,
    '24': 0.24,
    '32': 0.32,
    '40': 0.4,
    '48': 0.48,
    '64': 0.64,
    '72': 0.72,
    '80': 0.8,
    '96': 0.96,
    '100': 1,
  },
  colors: {
    ...palette,
    ...contextualBgPalette,
    ...contextualTextPalette,
    ...contextualIconPalette,
    transparent: 'transparent',
    white: '#FFF',
    black: '#000',
    inherit: 'inherit',
  },
  shadows:
    unit === 'px'
      ? (Object.fromEntries(
          Object.entries(shadows).map(([key, value]) => [key, remToPx(value)])
        ) as typeof shadows)
      : shadows,
})

export const overridePrimaryColor = (
  colors: Record<string, string> | undefined,
  primaryColor: string
): Record<string, string> => {
  return Object.entries(colors ?? {}).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[key] = value.replace(/hsl\(247, 100%, (?:62|80)%, (.*?)\)/g, `rgba(${primaryColor}, $1)`)
    return acc
  }, {})
}
