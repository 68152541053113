import React, { CSSProperties } from 'react'
import { animated, useTransition } from 'react-spring'
import ReactModal from 'react-modal'

import { LayoutProps } from 'styled-system'
import { useTheme } from '../../styled-components'
import { ThemeColors } from '../../new/theme'

/** @deprecated - Remove once all usages of {@link Modal} are removed from the app */
export const initializeModal = (selector?: string) => {
  try {
    ReactModal.setAppElement(selector ?? '#root, #__next')
  } catch (err) {
    console.error(err)
  }
}

const customStyles: ReactModal.Styles = {
  content: {
    position: 'relative',
    left: 'auto',
    top: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    border: 'none',
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: 'none',
    background: 'none',
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px',
    overflow: 'auto',
    zIndex: 11,
  },
}

type Props = ReactModal.Props & {
  bg?: ThemeColors
  children: React.ReactNode
  contentStyles?: CSSProperties
  overlayStyles?: CSSProperties
} & LayoutProps

/** @deprecated - Use Modal, from `@chilipiper/design-system/src/new` instead */
export const Modal = ({
  children,
  isOpen,
  style = {},
  overlayStyles = {},
  contentStyles = {},
  bg = 'bg/dim-overlay',
  parentSelector,
  ...others
}: Props) => {
  const transitions = useTransition(isOpen, {
    from: { opacity: `0` },
    config: {
      duration: 200,
    },
    enter: { opacity: `1` },
    leave: { opacity: `0` },
  })

  const theme = useTheme()

  return (
    <ReactModal
      parentSelector={parentSelector}
      closeTimeoutMS={300}
      isOpen={isOpen}
      style={{
        content: {
          ...customStyles.content,
          ...contentStyles,
        },
        overlay: {
          ...customStyles.overlay,
          background: theme.colors[bg],
          ...overlayStyles,
        },
        ...style,
      }}
      {...others}
    >
      {transitions(
        (style, item) =>
          item && <animated.div style={{ height: '100%', ...style }}>{children}</animated.div>
      )}
    </ReactModal>
  )
}
