/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgCheckCircleFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <g clipPath='url(#clip0_5760_38492)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.799805 8.00078C0.799805 4.02433 4.02336 0.800781 7.99981 0.800781C11.9763 0.800781 15.1998 4.02433 15.1998 8.00078C15.1998 11.9772 11.9763 15.2008 7.99981 15.2008C4.02336 15.2008 0.799805 11.9772 0.799805 8.00078ZM10.909 6.00661C11.2745 6.37214 11.2745 6.96478 10.909 7.33031L7.85427 10.385C7.67874 10.5605 7.44066 10.6592 7.19242 10.6592C6.94418 10.6592 6.7061 10.5605 6.53057 10.385L5.00322 8.85766C4.63769 8.49213 4.63769 7.89949 5.00322 7.53396C5.36875 7.16843 5.96139 7.16843 6.32692 7.53396L7.19242 8.39946L9.58527 6.00661C9.9508 5.64108 10.5434 5.64108 10.909 6.00661Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_5760_38492'>
        <rect width={16} height={16} fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default SvgCheckCircleFilled
