/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgAdd = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8 3a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2H9v3a1 1 0 1 1-2 0V9H4a1 1 0 0 1 0-2h3V4a1 1 0 0 1 1-1Z'
      fill='currentColor'
    />
  </svg>
)
export default SvgAdd
