import { remToPx } from './remToPx'

const space = [
  '0rem', // 0px 0
  // xs
  '0.25rem', // 4px 1
  '0.5rem', // 8px 2
  '0.75rem', // 12px 3
  '1rem', // 16px 4
  '1.25rem', // 20px 5
  '1.5rem', // 24px 6

  // sm
  '2rem', // 32px 7
  '2.5rem', // 40px 8
  '3rem', // 48px 9
  '3.5rem', // 56px 10
  '4rem', // 64px 11

  // md
  '5rem', // 80px 12
  '6rem', // 96px 13
  '7rem', // 112px 14
  '8rem', // 128px 15
  '9rem', // 144px 16

  // lg
  '11rem', // 176px 17
  '13rem', // 208px 18
  '15rem', // 240px 19
  '17rem', // 272px 20
  '19rem', // 304px 21

  // xl
  '23rem', // 368px 22
  '27rem', // 432px 23
  '31rem', // 496px 24
  '35rem', // 560px 25
  '39rem', // 624px 26

  // 2xl
  '47rem', // 752px 27
  '55rem', // 880px 28
  '63rem', // 1008px 29
  '71rem', // 1136px 30
  '79rem', // 1264px 31

  // 3xl
  '95rem', // 1520px 32
  '111rem', // 1776px 33
  '127rem', // 2032px 34
  '143rem', // 2288px 35
  '159rem', // 2544px 36
]

export const getSpacing = (unit?: 'px') => {
  if (unit === 'px') {
    const mappedSpace = space.map(s => remToPx(s))
    return {
      sizes: mappedSpace,
      space: mappedSpace,
    }
  }
  return {
    sizes: space,
    space,
  }
}
