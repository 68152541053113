import React from 'react'
import { Text } from '../../../new/core-components'
import { ConfirmationModalProps } from '../types'
import { BaseModalLayout } from '../BaseModalLayout'

/**
 * @deprecated - Use ConfirmationModal, ConfigurationModal or Modal from
 *   `@chilipiper/design-system/src/new` instead
 */
export const ConfirmationModal = ({
  title,
  description,
  variant = 'error',
  onCancel,
  onConfirm,
  ...rest
}: ConfirmationModalProps & { zIndex?: number }) => (
  <BaseModalLayout
    withDivider
    header={
      <Text textStyle='heading-m' m={0}>
        {title}
      </Text>
    }
    variant={variant}
    onClose={() => {
      onCancel()
    }}
    onCancelClick={onCancel}
    onConfirmClick={onConfirm}
    {...rest}
  >
    <Text textStyle='item-unselected' color='text/body-moderate'>
      {description}
    </Text>
  </BaseModalLayout>
)
