import React from 'react'
import { mergeProps, useButton } from 'react-aria'
import { SvgDropdown } from '@chilipiper/icons/src/design-system'
import { Popover } from '../../../../core-components/popover/Popover'
import { AriaProps } from '../../../types'
import { BaseActionBarButton } from '../BaseActionBarButton'
import { ThemeShadows } from '../../../../theme'
import { useFocusVisible } from '../../../hooks'
import { splitAriaProps } from '../../../helpers'

export type ActionBarOptionButtonPublicProps = {
  children: React.ReactNode
  'data-id'?: string
  'data-test-id'?: string
  isDisabled?: boolean
  isOpen?: boolean
  label: string
  onOpenChange?: (isOpen: boolean) => void
  startIcon?: React.ComponentType
  variant: 'major' | 'minor'
}

type InternalProps = {
  INTERNAL_hasNextSibling?: boolean
  INTERNAL_isFocusVisible?: boolean
  INTERNAL_isOnlyChild?: boolean
}

type Props = InternalProps & ActionBarOptionButtonPublicProps

export const ActionBarOptionButton = ({
  isDisabled,
  children,
  INTERNAL_isFocusVisible,
  'data-id': dataId,
  'data-test-id': dataTestId,
  INTERNAL_hasNextSibling,
  INTERNAL_isOnlyChild,
  label,
  variant,
  onOpenChange,
  isOpen,
  startIcon,
}: Props) => {
  return (
    <Popover
      placement='bottom start'
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      offset={8}
      trigger={ariaProps => {
        return (
          <Button
            data-id={dataId}
            data-test-id={dataTestId}
            variant={variant}
            INTERNAL_isFocusVisible={INTERNAL_isFocusVisible}
            INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
            INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
            isDisabled={isDisabled}
            startIcon={startIcon}
            ariaProps={ariaProps}
          >
            {label}
          </Button>
        )
      }}
    >
      {children}
    </Popover>
  )
}
ActionBarOptionButton.dsId = 'action-bar-option-button'

const Button = ({
  variant,
  ariaProps,
  isDisabled,
  INTERNAL_isFocusVisible,
  INTERNAL_isOnlyChild,
  INTERNAL_hasNextSibling,
  children,
  startIcon,
  'data-id': dataId,
  'data-test-id': dataTestId,
}: Pick<
  Props,
  | 'data-id'
  | 'data-test-id'
  | 'variant'
  | 'INTERNAL_isFocusVisible'
  | 'INTERNAL_hasNextSibling'
  | 'INTERNAL_isOnlyChild'
  | 'isDisabled'
  | 'startIcon'
> & {
  ariaProps: AriaProps
  children: string
}) => {
  const { elementRef, domProps, reactAriaProps } = splitAriaProps(ariaProps)
  const fallbackRef = React.useRef<HTMLButtonElement>(null)
  const ref = elementRef ?? fallbackRef
  const { buttonProps } = useButton({ ...reactAriaProps, isDisabled }, ref)
  const { focusProps, isFocusVisible } = useFocusVisible(INTERNAL_isFocusVisible)
  return (
    <BaseActionBarButton
      variant={variant}
      wrapper={{ as: 'button', ref }}
      startIcon={startIcon}
      endIcon={SvgDropdown}
      isFocusVisible={isFocusVisible}
      data-id={dataId}
      data-test-id={dataTestId}
      INTERNAL_isOnlyChild={INTERNAL_isOnlyChild}
      INTERNAL_hasNextSibling={INTERNAL_hasNextSibling}
      activeBorder={variantProps[variant].activeBorder}
      {...mergeProps(buttonProps, focusProps, domProps)}
    >
      {children}
    </BaseActionBarButton>
  )
}

const variantProps: Record<
  Props['variant'],
  {
    activeBorder: ThemeShadows
  }
> = {
  major: {
    activeBorder: 'border/action-focused-major',
  },
  minor: {
    activeBorder: 'border/action-focused-minor',
  },
}
