import React from 'react'
import { SvgCaution, SvgInform, SvgSuccess, SvgWarning } from '@chilipiper/icons/src/design-system'
import { Icon } from '../../../core-components/icon/Icon'
import { PositioningProps } from '../../types'

type Props = PositioningProps & {
  size: number
  variant: 'inform' | 'warning' | 'success' | 'caution' | 'neutral'
}

export const AlertIcon = ({ variant, size, ...positioningProps }: Props) => {
  const colorMap = {
    inform: 'icon/inform',
    warning: 'icon/warning',
    success: 'icon/success',
    caution: 'icon/error',
    neutral: 'icon/indicator',
  } as const
  const iconMap = {
    inform: SvgInform,
    warning: SvgWarning,
    success: SvgSuccess,
    caution: SvgCaution,
    neutral: SvgInform,
  }
  return (
    <Icon
      {...positioningProps}
      flexShrink={0}
      w={size}
      h={size}
      color={colorMap[variant]}
      icon={iconMap[variant]}
    />
  )
}
