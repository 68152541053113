/* do not edit. this file is generated by npm run lib:icons and constantly overwritten */
import React from 'react'
export const SvgDropdown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' {...props}>
    <path
      d='M7.444 10.17L4.232 6.946C3.737 6.45 4.087 5.6 4.788 5.6h6.424c.7 0 1.051.85.556 1.347l-3.212 3.222a.784.784 0 01-1.112 0z'
      fill='currentColor'
    />
  </svg>
)
export default SvgDropdown
