import React from 'react'
import { AriaPopoverProps, DismissButton, usePopover } from 'react-aria'
import { OverlayTriggerState } from 'react-stately'
import { SafeFocusScope } from '../internal-helpers/SafeFocusScope'

interface Props extends AriaPopoverProps {
  children: React.ReactNode
  focusProps?: Omit<React.ComponentProps<typeof SafeFocusScope>, 'children'>
  state: OverlayTriggerState
}

export const InternalPopover = ({ children, state, focusProps, ...props }: Props) => {
  const { popoverProps } = usePopover({ ...props, offset: 8 }, state) // ariaHideOutside is implicitly part of usePopover
  const popoverRef = props.popoverRef as React.RefObject<HTMLDivElement>

  return (
    <SafeFocusScope {...focusProps}>
      <div {...popoverProps} ref={popoverRef}>
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </SafeFocusScope>
  )
}
