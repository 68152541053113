import React, { ReactNode } from 'react'
import { ModalProvider } from './old/modal/modal-provider/ModalProvider'
import { SnackbarProvider } from './old/snackbar/SnackbarProvider' // do not simplify, it causes cyclic import
import { ThemeProviderClean } from './ThemeProviderClean'
import { Variation } from './new/theme'
import { ToastProvider } from './new/components'

interface Props {
  children: ReactNode
  variation?: Variation
}

export const Provider = ({ children, variation }: Props) => {
  return (
    <ThemeProviderClean variation={variation}>
      <SnackbarProvider>
        <ToastProvider>
          <ModalProvider>{children}</ModalProvider>
        </ToastProvider>
      </SnackbarProvider>
    </ThemeProviderClean>
  )
}
