import { ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children?: ReactNode
} & (
  | {
      element?: never
      selector: string
    }
  | {
      element: Element | ShadowRoot
      selector?: never
    }
)

/** Render children in portal after mount to avoid server/client mismatch during SSR */
export const ClientOnlyPortal = ({ children, selector, element }: Props) => {
  const ref = useRef<Element | ShadowRoot>()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (selector) {
      const element = document.querySelector(selector)
      if (!element) {
        return
      }
      ref.current = element
      setMounted(true)
    } else if (element) {
      ref.current = element
      setMounted(true)
    }
  }, [element, selector])

  if (!mounted || !ref.current) {
    return null
  }

  return createPortal(children, ref.current)
}
